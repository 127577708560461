import React, {useEffect, useRef, useState} from 'react';
import bg from '../assets/slide2.jpeg';
import Jumbotron from "../components/Jumbotron";
import axios from "axios";
import {viewAlert} from "../SweetAlert/SweetAlert";
import getSettingsList from "../api/settings";

const VisiMisiContent = () => {
    const [visi, setVisi] = useState('')
    const isMounted = useRef(false);
    useEffect(() => {
        if (isMounted.current) {
            return
        }
        isMounted.current = true;

        getSettingsList().then((res) => {
            setVisiMisi(res)
        }).catch(err => viewAlert("Masalah!", err, "error"));

        return () => {}
    }, []);

    const setVisiMisi = (list) => {
        list.forEach((item) => {
            if (item.key === "visi") {
                setVisi(item.value);
            }

            if (item.key === "misi") {
                document.getElementById('misiList').innerHTML = item.value;
            }
        })


    }

    return (
        <div className="text-gray-600">
            <Jumbotron title='Visi & Misi' />

            <section className="body-font mt-10 mb-10 desktop:ml-[20%] desktop:mr-[20%] tab:ml-[10%] tab:mr-[10%] phone:ml-5 phone:mr-5">
                <div className="flex items-center justify-center mb-10">
                    <h1 className="text-3xl font-bold">Visi</h1>
                </div>
                <div>
                    <p>{visi}</p>
                </div>

                <div className="flex items-center justify-center mb-5 mt-10">
                    <h1 className="text-3xl font-bold">Misi</h1>
                </div>
                <div className="ml-4">
                    <ol className="list-decimal" id="misiList">

                    </ol>
                </div>
            </section>
        </div>
    );
};

export default VisiMisiContent;