import React, {useEffect} from 'react'
import CustomeNavbar from '../components/CustomeNavbar'
import Header from '../components/Header'
import HomeContent from '../contents/HomeContent'
import Footer from "../components/Footer";
import ToTop from "../components/ToTop";

const Home = () => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        scrollToTop();
    }, []);
  return (
    <div>
        {/*<div className='demo-text'>DEMO</div>*/}
        <Header />
        <CustomeNavbar />
        <HomeContent />
        <ToTop />
        <Footer />
    </div>
  )
}

export default Home