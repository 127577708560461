import React, {useRef, useState} from 'react';
import axios from "axios";
import {viewAlert} from "../SweetAlert/SweetAlert";
import {useNavigate} from "react-router-dom";

const PelaporForm = () => {
    const navigate = useNavigate();
    const [namaPelapor, setNamaPelapor] = useState(''),
        [emailPelapor, setEmailPelapor] = useState(''),
        [phonePelapor, setPhonePelapor] = useState(''),
        [terduga, setTerduga] = useState(''),
        [jabatan , setJabatan] = useState(''),
        [unitkerja, setUnitkerja] = useState(''),
        [jenispelanggaran, setJenispelanggaran] = useState(''),
        [tempatpelanggaran, setTempatpelanggaran] = useState(''),
        [waktupelanggaran, setWaktupelanggaran] = useState(''),
        [kronologi, setKronologi] = useState(''),
        [files, setFiles] = useState(null),
        fileinput =useRef(null),
        [bgButton, setBgButton] = useState('bg-blue-950'),
        [idComplaint, setIdComplaint] = useState('')

    const jenisPelanggaraList = [
        '-- Pilih Jenis Pelanggaran --',
        'Penyimpangan dari Tugas dan Fungsi',
        'Gratifikasi',
        'Benturan Kepentingan',
        'Melanggar Peraturan dan perundangan yang berlaku',
        'Tindak pidana korupsi',
        'Lain - Lain'
    ]

    const handlePost = async (listfile) => {

        let filepathlist = []

        listfile.forEach((file) => {
            filepathlist.push(file.path)
        })

        let dtsend = {
            name: namaPelapor,
            email: emailPelapor,
            phoneNumber: phonePelapor,
            offenderName: terduga,
            offenderTitle: jabatan,
            workUnit: unitkerja,
            violationType: jenispelanggaran,
            violationPlace: tempatpelanggaran,
            violationTime: waktupelanggaran,
            chronology: kronologi,
            attachments: filepathlist
        }

        return await axios.post(
            "/api/complaint",
            dtsend,
        );
    }

    const setCookie = (name, value, days) => {
        let expires = "";
        if (days) {
            let date = new Date();
            // date.setTime(date.getTime() + (days * 60 * 1000)); // for minute
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000)); // Convert days to milliseconds
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }

    const handleFileUpload = async (file) => {
        return new Promise(async (resolve, reject) => {
            document.getElementById('buttonSbmtPengaduan').setAttribute('disabled', 'true');
            setBgButton('bg-gray-500');
            if (file !== null) {
                let filelistid = [];
                for (let i = 0; i < file.length; i++) {
                    const formData = new FormData();
                    formData.append('files', file[i]);
                    await axios.post('/api/file/complaints', formData).then((res) => {
                        console.log(res);
                        setBgButton('bg-blue-950');
                        document.getElementById('buttonSbmtPengaduan').removeAttribute('disabled');
                        const fileid = res.data[0];
                        filelistid.push(fileid);
                    }).catch((err) => {
                        console.log(err);
                        setBgButton('bg-blue-950');
                        document.getElementById('buttonSbmtPengaduan').removeAttribute('disabled');
                        reject(err);
                    });
                }
                resolve(filelistid)
            } else {
                resolve([])
            }
        })

    }

    const resetForm = () => {
        setNamaPelapor('')
        setEmailPelapor('')
        setPhonePelapor('')
        setTerduga('')
        setJabatan('');
        setUnitkerja('')
        setJenispelanggaran('')
        setTempatpelanggaran('')
        setWaktupelanggaran('')
        setKronologi('')
        resetFile()
    }

    const resetFile = () => {
        fileinput.current.value = ''
    }

    const getCookiesForm = (name) => {
        const nameEQ = name + "=";
        const cookies = document.cookie.split(';');

        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i];

            // Trim any leading whitespace characters
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }

            // If the cookie name matches, return its value
            if (cookie.indexOf(nameEQ) === 0) {
                return cookie.substring(nameEQ.length, cookie.length);
            }
        }

        // Return null if the cookie was not found
        return null;
    }

    const handleSubmit = () => {
        if (files !== null) {
            handleFileUpload(files).then((filesidlist) => {
                handlePost(filesidlist).then((res) => {
                    if (res.status === 200 || res.status === 201) {
                        const fileLocal = JSON.parse(getCookiesForm('ID'))
                        let complainlist = [];
                        if (fileLocal === null) {
                            complainlist.push(res.data.id);
                        } else {
                            fileLocal.forEach((item) => {
                                complainlist.push(item);
                            })

                            complainlist.push(res.data.id);
                        }

                        setCookie("ID", JSON.stringify(complainlist), 7);
                        viewAlert('Terkirim!', 'Pengaduan Sukses di kirim, terima kasih telah melaporkan kejadian ini.', 'success')
                        resetForm()
                    }
                }).catch(error => {
                    viewAlert('Gagal!', 'Pengaduan gagal di kirim, silahkan coba lagi! \n Error: ' + JSON.stringify(error.response.data.message), 'error')
                })
            }).then(() => {
                handleRedirect()
            }).catch(err => {
                viewAlert('Gagal!', err, 'error')
            })
        } else {
            handlePost([]).then((res) => {
                if (res.status === 200 || res.status === 201) {
                    console.log(res.data);
                    const fileLocal = JSON.parse(getCookiesForm('ID'))
                    let complainlist = [];
                    if (fileLocal === null) {
                        complainlist.push(res.data.id);
                    } else {
                        fileLocal.forEach((item) => {
                            complainlist.push(item);
                        })

                        complainlist.push(res.data.id);
                    }

                    setCookie("ID", JSON.stringify(complainlist), 7);

                    viewAlert('Terkirim!', 'Pengaduan Sukses di kirim, terima kasih telah melaporkan kejadian ini.', 'success')
                    resetForm()

                }
            }).then(() => {
                handleRedirect()
            }).catch(error => {
                viewAlert('Gagal!', 'Pengaduan gagal di kirim, silahkan coba lagi! \n Error: ' + JSON.stringify(error.response.data.message), 'error')
            })
        }
    }

    const handleRedirect = () => {
        navigate('/lacakpengaduan'); // Redirect to /target-route
    };

    const formatDate = (input) => {
        const date = new Date(input);

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    return (
        <div>
            <div className='grid desktop:grid-cols-2 phone:grid-cols-1 mt-10'>
                <div className='m-5'>
                    <h1 className='text-2xl mb-5'>Data Pelapor</h1>
                    <div className='mb-5 ml-6'>
                        <label className="block text-gray-700 text-sm font-bold mb-2">Nama*</label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={namaPelapor}
                            onChange={(e) => setNamaPelapor(e.target.value)}
                        />
                    </div>
                    <div className='mb-5 ml-6'>
                        <label className="block text-gray-700 text-sm font-bold mb-2">Email*</label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={emailPelapor}
                            onChange={(e) => setEmailPelapor(e.target.value)}
                        />
                    </div>
                    <div className='mb-5 ml-6'>
                        <label className="block text-gray-700 text-sm font-bold mb-2">Nomor Telefon*</label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={phonePelapor}
                            onChange={(e) => setPhonePelapor(e.target.value)}
                        />
                    </div>
                </div>
                <div className='m-5'>
                    <h1 className='text-2xl mb-5'>Data Terlapor</h1>
                    <div className='mb-5 ml-6 mr-6'>
                        <label className="block text-gray-700 text-sm font-bold mb-2">Nama Terduga*</label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={terduga}
                            onChange={(e) => setTerduga(e.target.value)}
                        />
                    </div>
                    <div className='mb-5 ml-6 mr-6'>
                        <label className="block text-gray-700 text-sm font-bold mb-2">Jabatan Terduga*</label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={jabatan}
                            onChange={(e) => setJabatan(e.target.value)}
                        />
                    </div>
                    <div className='mb-5 ml-6 mr-6'>
                        <label className="block text-gray-700 text-sm font-bold mb-2">Unit Kerja*</label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={unitkerja}
                            onChange={(e) => setUnitkerja(e.target.value)}
                        />
                    </div>
                    <div className='mb-5 ml-6 mr-6'>
                        <label className="block text-gray-700 text-sm font-bold mb-2">Jenis Pelanggaran*</label>
                        {/*<input*/}
                        {/*    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"*/}
                        {/*    value={jenispelanggaran}*/}
                        {/*    onChange={(e) => setJenispelanggaran(e.target.value)}*/}
                        {/*/>*/}
                        <select
                            className='border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                            value={jenispelanggaran}
                            onChange={(e) => setJenispelanggaran(e.target.value)}
                        >
                            {jenisPelanggaraList.map((list,index) => (
                                <option key={index}>{list}</option>
                            ))}
                        </select>
                    </div>
                    <div className='mb-5 ml-6 mr-6'>
                        <label className="block text-gray-700 text-sm font-bold mb-2">Tempat Pelanggaran*</label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={tempatpelanggaran}
                            onChange={(e) => setTempatpelanggaran(e.target.value)}
                        />
                    </div>
                    <div className='mb-5 ml-6 mr-6'>
                        <label className="block text-gray-700 text-sm font-bold mb-2">Waktu Pelanggaran*</label>
                        <input
                            type='datetime-local'
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={waktupelanggaran}
                            onChange={(e) => setWaktupelanggaran(formatDate(e.target.value))}
                        />
                    </div>
                    <div className='mb-5 ml-6 mr-6'>
                        <label className="block text-gray-700 text-sm font-bold mb-2">Kronologi*</label>
                        <textarea
                            rows='5'
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={kronologi}
                            onChange={(e) => setKronologi(e.target.value)}
                        />
                    </div>
                    <div className='mb-5 ml-6 mr-6'>
                        <label className="block text-gray-700 text-sm font-bold mb-2">Bukti Pelanggaran*</label>
                        <input
                            type='file'
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            onChange={(e) => setFiles(e.target.files)}
                            ref={fileinput}
                            multiple
                        />
                    </div>
                </div>
            </div>
            <div className='flex items-center justify-center m-10'>
                <button
                    id='buttonSbmtPengaduan'
                    className={`${bgButton} text-white p-3 rounded-lg hover:bg-blue-900`}
                    onClick={handleSubmit}
                >Submit
                </button>
            </div>
        </div>
    );
};

export default PelaporForm;