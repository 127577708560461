import React from 'react';
import FadeIn from "../components/FadeIn";
import profilinspektur from "../assets/inspektur.jpeg";

const ProfileContent = () => {
    const ytLink = 'https://www.youtube.com/embed/2JlNz9JY6u0';

    return (
        <div>
            <section className="body-font mt-10 mb-20">
                <div className='flex items-center justify-center mb-2'>
                    <h1 className='text-2xl'>Profil Pimpinan</h1>
                </div>
                <div className='pl-10 pr-10'>
                    <div className='flex items-center justify-center'>
                        <div className='grid desktop:grid-cols-2 tab:grid-cols-2 phone:grid-cols-1 desktop:p-10 tab:p-5 phone:p-3'>
                            <div className='flex flex-col items-end justify-start desktop:pl-10 tab:pl-5 phone:pl-1'>
                                <div className='flex flex-col items-end justify-end'>
                                    <div className='desktop:mb-2 phone:mb-1'>
                                        <FadeIn delay={0.2} direction='right' fullWidth>
                                            <p className='desktop:text-3xl phone:text-sm'>Inspektur</p>
                                        </FadeIn>
                                    </div>
                                    <div className='desktop:mb-5 phone:mb-1'>
                                        <FadeIn delay={0.3} direction='right' fullWidth>
                                            <div>
                                                <p className='desktop:text-4xl font-bold tab:text-2xl phone:text-md text-right'>Apniel Pongtulurun, S.Kom.,MM</p>
                                                <p className='text-right desktop:text-lg phone:text-sm'>NIP: 197904112005021005</p>
                                            </div>
                                        </FadeIn>
                                    </div>
                                </div>
                                <div className='flex flex-col items-end justify-end desktop:pl-10 tab:pl-5 phone:pl-1'>
                                    <FadeIn delay={0.4} direction='right' fullWidth>
                                        <div className='flex flex-col'>
                                            <p className='text-right font-bold desktop:text-xl phone:text-sm'>Biodata</p>
                                            <div className='mt-2'>
                                                <p className='text-right desktop:text-[15px] tab:text-[15px] phone:text-[12px] font-bold'>Tempat Tanggal Lahir</p>
                                                <p className='text-right desktop:text-[18px] tab:text-[15px]'>Nabire, 11 April 1979</p>
                                            </div>
                                            <div className='mt-2 mb-5'>
                                                <p className='text-right desktop:text-[15px] tab:text-[15px] phone:text-[12px] font-bold'>
                                                    Agama
                                                </p>
                                                <p className='text-right desktop:text-[18px] tab:text-[15px]'>Kristen Protestan</p>
                                            </div>
                                            <hr />
                                            <p className='text-right desktop:text-xl phone:text-sm mt-5 mb-2 font-bold'>
                                                Karir Kerja
                                            </p>
                                            <div className=''>
                                                <p className='text-right desktop:text-[15px] tab:text-[15px] phone:text-[12px] font-bold'>2005-2008</p>
                                                <p className='text-right desktop:text-[18px] tab:text-[15px] phone:text-[12px]'>Staf
                                                    pada Dinas Keuangan dan Aset Daerah Kab.Nabire</p>
                                            </div>
                                            <div className='mt-5 desktop:ml-20 phone:ml-2'>
                                                <p className='text-right desktop:text-[15px] tab:text-[15px] phone:text-[12px] font-bold'>2013-2023</p>
                                                <p className='text-right desktop:text-[18px] tab:text-[15px] phone:text-[12px]'>
                                                    Kepala Bidang Anggaran dan Otorisasi pada Badan Keuangan dan Aset
                                                    Daerah
                                                    Kab.Paniai
                                                </p>
                                            </div>
                                            <div className='mt-5'>
                                                <p className='text-right desktop:text-[15px] tab:text-[15px] phone:text-[12px] font-bold'>2023-Sekarang</p>
                                                <p className='text-right desktop:text-[18px] tab:text-[15px] phone:text-[12px]'>
                                                    Inspektur Kab.Paniai
                                                </p>
                                            </div>
                                        </div>
                                    </FadeIn>
                                </div>
                            </div>
                            <div className='phone:mt-5 dektop:mt-0'>
                                <div className='desktop:w-[50%] desktop:h-[100%]'>
                                    {/*foto pimpinan*/}
                                    <div className='desktop:pl-10 desktop:pr-10 phone:pl-2'>
                                        <FadeIn delay={1.5} direction='right' fullWidth>
                                            <img alt={'img'}
                                                 src={profilinspektur}
                                                 className='object-cover w-[100%] h-[100%]'/>
                                        </FadeIn>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-gray-100 body-font mt-10">
                <div className='flex items-center justify-center p-10'>
                    <h1 className='text-2xl'>Tentang Kabupaten Paniai</h1>
                </div>
                <div className='grid desktop:grid-cols-2 phone:grid-cols-1 pb-20'>
                    <div className='flex items-start justify-start pl-10 phone:pr-10 phone:mb-5 desktop:pr-1'>
                        <div className='w-[100%] desktop:h-[500px] phone:h-[100%]'>
                            <FadeIn delay={0.2} direction='left' fullWidth>
                                <img className='object-cover w-[100%] h-[100%]' alt={'img'} src={'https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Lokasi_Papua_Tengah_Kabupaten_Paniai.svg/1280px-Lokasi_Papua_Tengah_Kabupaten_Paniai.svg.png'} />
                            </FadeIn>
                        </div>
                    </div>
                    <div className='flex items-start justify-center pl-10 pr-10'>
                        <div className='desktop:text-lg phone:text-sm'>
                            <FadeIn delay={0.3} direction='right' fullWidth>
                                <p>Kabupaten Paniai adalah salah satu kabupaten di provinsi Papua Tengah, Indonesia. Letaknya berada di pedalaman pada ketinggian 1.700 meter di atas permukaan laut. Paniai pada zaman Belanda disebut Wisselmeren, sesuai dengan nama tiga danau yang terletak sekitar pusat kota Enarotali. Danau ini ditemukan oleh seorang pilot berkebangsaan Belanda, Frits Julius Wissel pada tahun 1938 (danau dalam bahasa Belanda disebut meer sedangkan dalam bentuk jamak disebut meeren). Karena Frits Wissel yang menemukan danau-danau tersebut maka kemudian dikenal dengan nama Wisselmeren. Sejak saat itu masyarakat Paniai mulai berinteraksi dengan dunia luar. </p>
                            </FadeIn>
                            <FadeIn delay={0.4} direction='right' fullWidth>
                                <p>Kabupaten yang memiliki luas 6.526,25 km² ini beribu kota di Enarotali. Jenis angkutan udara menjadi sarana yang sangat penting di kabupaten ini. Terdapat lima belas lapangan terbang yang tersebar di wilayah kabupaten ini (sebelas di antaranya merupakan milik swasta) dengan bandar udara utama di Enarotali. Pada pertengahan tahun 2023, jumlah penduduk Paniai sebanyak 123.360 jiwa.</p>
                            </FadeIn>
                            <FadeIn delay={0.5} direction='right' fullWidth>
                                <p>Paniai merupakan kabupaten yang terletak di pegunungan sehingga memiliki kecenderungan suhu udara yang rendah dan kelembaban udara yang relatif tinggi. Di Kabupaten Paniai suhu udara maksimum 24,6 derajat celcius dan rata-rata kelembaban udara 82,3%.</p>
                            </FadeIn>
                        </div>
                    </div>
                </div>
            </section>

            <section className="body-font mt-10">
                <div className='flex items-center justify-center pb-10'>
                    <h1 className='text-2xl'>Tentang Inspektorat Daerah</h1>
                </div>
                <div className='grid desktop:grid-cols-2 phone:grid-cols-1 gap-5 pb-20'>
                    <div className='flex items-start justify-center pl-10 pr-10 desktop:text-lg phone:text-sm'>
                        <FadeIn delay={0.2} direction='left' fullWidth>
                        <p>Inspektorat Daerah adalah unsur penunjang kepala daerah yang berada di bawah dan bertanggung
                            jawab kepada kepala daerah. Inspektorat Daerah dipimpin oleh seorang Inspektur Daerah yang
                            berkedudukan sebagai unsur pimpinan tinggi pratama. Inspektorat Daerah mempunyai tugas
                            membantu kepala daerah dalam menyelenggarakan pengawasan, pemeriksaan, dan pengendalian
                            terhadap penyelenggaraan pemerintahan daerah.</p>
                        </FadeIn>
                    </div>
                    <div className='flex items-start justify-center pl-10 pr-10'>
                        <div className='desktop:text-lg phone:text-sm'>
                            <FadeIn delay={0.3} direction='right' fullWidth>
                                <p>Inspektorat Daerah mempunyai fungsi:</p>
                            </FadeIn>
                            <FadeIn delay={0.4} direction='right' fullWidth>
                                <ol className='list-decimal'>
                                    <li>Penyusunan rencana kerja pengawasan, pemeriksaan, dan pengendalian;</li>
                                    <li>Pelaksanaan pengawasan, pemeriksaan, dan pengendalian;</li>
                                    <li>Pelaksanaan evaluasi dan pelaporan pengawasan, pemeriksaan, dan pengendalian;</li>
                                    <li>Pelaksanaan administrasi Inspektorat Daerah;</li>
                                    <li>Pelaksanaan bimbingan teknis dan supervisi atas pelaksanaan tugas pengawasan, pemeriksaan, dan pengendalian;</li>
                                    <li>Pelaksanaan pengelolaan barang milik daerah yang menjadi tanggung jawab Inspektorat Daerah;</li>
                                    <li>Pelaksanaan tugas lain yang diberikan oleh kepala daerah sesuai dengan tugas dan fungsinya.</li>
                                </ol>
                            </FadeIn>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-gray-100 body-font mt-10">
                <div className='flex items-center justify-center pt-10'>
                    <p className='text-2xl'>Video Tentang Inspektorat Kab. Paniai</p>
                </div>
                <div className='pb-10'>
                    {/* List Video Youtube */}
                    <div className='card shadow-lg flex items-center justify-center ml-10 mr-10'>
                        <FadeIn delay={0.2} direction='down' fullWidth>
                            <iframe width="100%" height="500" src={ytLink}
                                    title="YouTube video player"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    className='mt-10'/>
                        </FadeIn>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default ProfileContent;