import React, {useEffect, useRef, useState} from 'react';
import {viewAlert} from "../SweetAlert/SweetAlert";
import axios from "axios";
import getVisitorsData from "../api/visitors";


const Visitor = () => {
    const [dailyVisitors, setDailyVisitor] = useState(0);
    const [weeklyVisitors, setWeeklyVisitor] = useState(0);
    const [monthlyVisitors, setMonthlyVisitor] = useState(0);
    const isMounted = useRef(false);

    useEffect(() => {
        if (isMounted.current) {
            return
        }
        isMounted.current = true;
        getVisitorsData().then((visitorData) => {
            setDailyVisitor(visitorData.daily)
            setWeeklyVisitor(visitorData.weekly)
            setMonthlyVisitor(visitorData.total)
        }).catch(err => viewAlert('Error', err, 'error'))

        return () => {}
    }, []);

    return (
        <div className="flex flex-row mt-5">
            <div className="bg-gray-100 text-sm text-left p-2 rounded-lg shadow-md border-2">
                <h2 className="font-bold mb-1">Statistik Pengunjung</h2>
                <div className="flex flex-row justify-between">
                    <p className="text-gray-700">Hari Ini: </p>
                    <span className="font-bold">{dailyVisitors}</span>
                </div>
                <div className="flex flex-row justify-between">
                    <p className="text-gray-700">Minggu Ini:</p>
                    <span className="font-bold">{weeklyVisitors}</span>
                </div>
                <div className="flex flex-row justify-between">
                    <p className="text-gray-700">Total :</p>
                    <span className="font-bold">{monthlyVisitors}</span>
                </div>
            </div>
        </div>
    );
};

export default Visitor;