import React from 'react';
import Slider from 'react-slick';
import slide1 from "../assets/slide1.jpeg"
import slide2 from "../assets/slide2.jpeg"
import slide3 from "../assets/slide3.jpeg"

const GaleryCarousel = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
    };

    const galeri = [
        { foto: slide1},
        { foto: slide2},
        { foto: slide3},
        { foto: 'https://via.placeholder.com/500x300'},
        { foto: 'https://via.placeholder.com/500x300'},
        { foto: 'https://via.placeholder.com/500x300'},
        { foto: 'https://via.placeholder.com/500x300'},
        { foto: 'https://via.placeholder.com/500x300'},
        { foto: 'https://via.placeholder.com/500x300'}
    ]

    return (
        <Slider {...settings}>
            {
                galeri.map((item, index) => {
                    return (
                        <div key={index} className='p-1'>
                            <img alt='img' src={item.foto} className='object-cover' />
                        </div>
                    )
                })
            }
        </Slider>
    );
};

export default GaleryCarousel;