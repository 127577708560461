import React from 'react';
import { motion } from 'framer-motion';
import {Link} from "react-router-dom";

const MenuCard = ({title, icon, to, description}) => {
    return (
        <motion.div
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            whileHover={{ scale: 1.05, color: 'gray' }}
            whileTap={{ scale: 0.95 }}
            className="bg-white rounded-lg p-4 w-auto h-auto m-4 hover:shadow-lg hover:rounded-lg transition-transform duration-300"
        >
            <div className='mx-auto flex items-center justify-center desktop:text-sm tab:text-sm phone:text-sm text-gray-700 mb-6 mt-2'>{icon}</div>
            <p className='desktop:text-sm tab:text-sm phone:text-sm text-gray-400 text-center mb-7'>{description}</p>
            <Link to={to} className='bg-blue-900 text-white rounded-md p-2 hover:bg-blue-950'>
                <button >{title}</button>
            </Link>
        </motion.div>
    );
};

export default MenuCard;