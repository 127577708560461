import React, {useEffect, useState} from 'react';
import TablePeraturan from "../components/TablePeraturan";
import Jumbotron from "../components/Jumbotron";
import axios from "axios";

const PeraturanContent = () => {
    const [document, setDocument] = useState([]);

    useEffect(() => {
        getDocuments().then(res => {
            const response = res.data;
            let peraturanTemp = [];
            response.forEach((image) => {
                if (image.directory === "documents") {
                    peraturanTemp.push(image)
                }
            })
            setDocument(peraturanTemp)
        })
    }, []);

    const getDocuments = () => {
        return new Promise(async (resolve, reject) => {
            await axios.get('/api/file/documents').then((response) => {
                resolve(response);
            }).catch((error) => {
                reject(error);
            })
        })
    }

    return (
        <div>
            <Jumbotron title='Dokumen Publikasi' />

            <section className='flex flex-col items-center justify-center pt-10 pb-20'>
                <div className='flex flex-col items-center justify-center pb-10'>
                    <p className='desktop:text-xl phone:text-lg'>Informasi Publik</p>
                    <p></p>
                </div>
                <div className='desktop:w-[1000px]'>
                    <TablePeraturan data={document}/>
                </div>
            </section>
        </div>
    );
};

export default PeraturanContent;