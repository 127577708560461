import React from 'react';

const NotFoundContent = () => {
    return (
        <div className=''>
            <div className='flex flex-col items-center justify-center pt-[8rem] pb-[12rem] bg-gray-200'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="flex desktop:w-[200px] desktop:h-[200px] phone:w-[100px] phone:h-[100px] text-red-500">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
                </svg>

                <div className='flex desktop:mt-10'>
                    <h1 className='flex font-medium desktop:text-3xl phone:text-xl phone:m-3 text-gray-700'>Page Not Found | Under Development!</h1>
                </div>
            </div>
        </div>
    );
};

export default NotFoundContent;