import React, {useEffect} from 'react';
import axios from "axios";
import struktur from '../assets/struktur.jpeg'
import FadeIn from "../components/FadeIn";
const qs = require("qs");

const PegawaiContent = () => {
    const [pemimpin, setPemimpin] = React.useState(null);
    const [pegawai, setpegawai] = React.useState([]);
    const [anggota, setAnggota] = React.useState([]);

    useEffect(() => {
        getPegawai().then((res) => {
            const response = res.data;
            let pegawaitemp = []
            let anggotatemp = []
            response.forEach((pegawai) => {
                if (pegawai.position === 'Inspektur') {
                    setPemimpin(pegawai)
                } else {
                    pegawaitemp.push(pegawai)
                }
            })
            setpegawai(pegawaitemp)
            setAnggota(response)
        })
    }, []);

    const getPegawai = () => {
        return new Promise(async (resolve, reject) => {
            const obj = {
                filter: {

                },
                // fields: "id,slug,title,description,image,createdAt",
                limit: 20,
                page: 1,
                sort: "-createdAt",
            }

            const stringQuery = qs.stringify(obj, {allowDots: true});

            await axios.get('/api/civil-servant?' + stringQuery).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            })
        })
    }
    return (
        <>
            <div className='flex flex-col items-center justify-center'>
                <section className="body-font mt-10">
                    <FadeIn delay={0.2} direction='left' fullWidth>
                        <img src={`${struktur}`} className='w-[1280px]' alt='struktur'/>
                    </FadeIn>
                </section>

                <section className='body-font max-w-7xl bg-gray-200 mt-5 mb-20'>
                    <div className='flex items-center justify-center m-10'>
                        <h1 className='text-2xl'>Data Pegawai Inspektorat</h1>
                    </div>
                    <div className="overflow-x-auto">
                        <table className="min-w-full bg-white border border-gray-200">
                            <thead>
                            <tr>
                                <th className="py-2 px-4 border-b">Nama</th>
                                <th className="py-2 px-4 border-b">Jabatan</th>
                                <th className="py-2 px-4 border-b">NIP</th>
                                <th className="py-2 px-4 border-b">Telfon</th>
                                <th className="py-2 px-4 border-b">Email</th>
                                <th className="py-2 px-4 border-b">Alamat</th>
                            </tr>
                            </thead>
                            <tbody>
                            {anggota.map((item) => (
                                <tr key={item.id}>
                                    <td className="py-2 px-4 border-b">{item.name}</td>
                                    <td className="py-2 px-4 border-b">{item.position}</td>
                                    <td className="py-2 px-4 border-b">{item.nip}</td>
                                    <td className="py-2 px-4 border-b">{item.phoneNumber}</td>
                                    <td className="py-2 px-4 border-b">{item.email}</td>
                                    <td className="py-2 px-4 border-b">{item.address}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </section>
            </div>
        </>
    );
};

export default PegawaiContent;