import React, {useEffect} from 'react';
import Header from "../components/Header";
import CustomeNavbar from "../components/CustomeNavbar";
import ToTop from "../components/ToTop";
import Footer from "../components/Footer";
import LHKPNPejabatContent from "../contents/LHKPNPejabatContent";

const LhkpnPejabat = () => {
    const listPejabat = [
        // { nama: 'Pejabat 1', jabatan: 'Jabatan 1', linkDownload: '#' },
        // { nama: 'Pejabat 2', jabatan: 'Jabatan 2', linkDownload: '#' },
        // { nama: 'Pejabat 3', jabatan: 'Jabatan 3', linkDownload: '#' },
        // { nama: 'Pejabat 4', jabatan: 'Jabatan 4', linkDownload: '#' },
        // { nama: 'Pejabat 5', jabatan: 'Jabatan 5', linkDownload: '#' },
        // { nama: 'Pejabat 6', jabatan: 'Jabatan 6', linkDownload: '#' },
        // { nama: 'Pejabat 7', jabatan: 'Jabatan 7', linkDownload: '#' },
    ];

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        scrollToTop();
    }, []);

    return (
        <div>
            <Header/>
            <CustomeNavbar/>
            <LHKPNPejabatContent listPejabat={listPejabat} />
            <ToTop/>
            <Footer/>
        </div>
    );
};

export default LhkpnPejabat;