import { RouterProvider, createBrowserRouter } from "react-router-dom"
import Home from "./pages/Home";
import Pengaduan from "./pages/Pengaduan";
import PageNotFound from "./pages/PageNotFound";
import Profile from "./pages/Profile";

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Peraturan from "./pages/Peraturan";
import VisiMisi from "./pages/VisiMisi";
import SurveiMasyarakat from "./pages/SurveiMasyarakat";
import PelaporanGratifikasi from "./pages/PelaporanGratifikasi";
import Berita from "./pages/Berita";
import Galeri from "./pages/Galeri";
import LHKPNPejabat from "./pages/LHKPNPejabat";
import {useEffect} from "react";
import PrintPengaduan from "./pages/PrintPengaduan";
import TugasFungsi from "./pages/TugasFungsi";
import DetailBerita from "./pages/DetailBerita";
import Pegawai from "./pages/Pegawai";
import DocumentPreview from "./components/DocumentPreview";
import SuratInternal from "./pages/SuratInternal";
import Pasal from "./pages/Pasal";
import LacakPengaduan from "./pages/LacakPengaduan";

const App = () => {
  const router = createBrowserRouter([
    { path: '/', element: <Home /> },
    { path: '/pengaduan', element: <Pengaduan /> },
    { path: '/profil', element: <Profile /> },
    { path: '/dokumen', element: <Peraturan /> },
    { path: '/visimisi', element: <VisiMisi /> },
    { path: '/tugas', element: <TugasFungsi /> },
    { path: '/surveimasyarakat', element: <SurveiMasyarakat />},
    { path: '/laporgratifikasi', element: <PelaporanGratifikasi />},
    { path: '/berita', element: <Berita />},
    { path: '/berita/:slug', element: <DetailBerita />},
    { path: '/galeri', element: <Galeri />},
    { path: '/lhkpnpejabat', element: <LHKPNPejabat />},
    { path: '/print', element: <PrintPengaduan />},
    { path: '/pegawai', element: <Pegawai /> },
    { path: '/peraturan', element: <Pasal /> },
    { path: '/suratinternal', element: <SuratInternal /> },
    { path: '/preview/:id', element: <DocumentPreview />},
    { path: '/lacakpengaduan', element: <LacakPengaduan /> },
    { path: '/*', element: <PageNotFound /> }
  ])

  return (
      <RouterProvider router={router}/>
  );
}

export default App;
