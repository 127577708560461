import React from 'react';
import IconTimer from "../icons/IconTimer";
import {Link} from "react-router-dom";

const BeritaList = ({list, onNewClick}) => {

    const formatDate = (createdAt) => {
        console.log(createdAt);
        if (createdAt === null) {
            return ""
        }
        return createdAt.split('T')[0].split('-')[2] + '/' + createdAt.split('T')[0].split('-')[1] + '/' + createdAt.split('T')[0].split('-')[0] + ' ' + createdAt.split('T')[1].replace('Z', '')
    }

    console.log(list);
    return (
        <div className='m-5'>
            {list.map((article) => (
                <div key={article.id} className="max-w-full bg-white rounded-xl overflow-hidden shadow-lg phone:mb-5">
                    <img className="w-full h-48 object-cover object-center"
                         src={`${article.image}`} alt="Berita"/>
                    <div className="p-6">
                        <h2 className="text-xl font-bold text-gray-800">{article.title}</h2>
                        <article className="mt-2 text-gray-600" dangerouslySetInnerHTML={{__html: article.description}}></article>
                        <div className='flex items-center justify-between'>
                            <div></div>
                            <div></div>
                        </div>
                        <div className='flex flex-row'>
                            <div className='mt-3'>
                                <IconTimer className='w-4'/>
                            </div>
                            <div>
                                <p className="mt-2 text-gray-400">{formatDate(article.createdAt)}</p>
                            </div>
                        </div>
                        <div className="mt-4">
                            <Link className="text-blue-500 font-semibold hover:text-blue-700" to={`/berita/${article.slug}`}>Baca Selengkapnya</Link>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default BeritaList;