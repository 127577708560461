import axios from "axios";

export const getBannerImage = () => {
    return new Promise(async (resolve, reject) => {
        await axios.get('/api/file/banners').then((res) => {
            let result = [];
            res.data.forEach(item => {
                let dataimg = {
                    path: item.path,
                }
                result.push(dataimg);
            })
            resolve(result);
        }).catch(err => {
            reject(err);
        })
    })
 }