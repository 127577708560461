import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import axios from "axios";
import {viewAlert} from "../SweetAlert/SweetAlert";
import DetailBerita from "../components/berita/DetailBerita";
import Loading from "../components/Loading";

const DetailBeritaContent = () => {
    const { slug} = useParams();
    const [articles, setArticles] = useState(null);
    const iklan = [
        { id: '1', src: 'https://via.placeholder.com/500'},
        { id: '2', src: 'https://via.placeholder.com/500'},
        { id: '3', src: 'https://via.placeholder.com/500'},
    ]

    useEffect(() => {
        fetchArticles(slug).then((res) => {
            setArticles(res)
        }).catch((err) => {
            viewAlert('Masalah', err, 'error')
        })
    }, []);

    const fetchArticles = async (id) => {
        return new Promise(async (resolve, reject) => {
            await axios.get('/api/article/' + id + '?fields=id,slug,image,content,title,createdAt').then((res) => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            })
        })
    }

    return (
        <div>
            <div className='grid desktop:grid-flow-row-dense desktop:grid-cols-3 phone:grid-cols-1 h-[100%] bg-white desktop:ml-20 desktop:mr-20 mb-10'>
                <div className='flex items-start justify-center desktop:col-span-2'>
                    {articles !== null ? (
                        <DetailBerita article={articles} />
                    ): (
                        <Loading />
                    )}
                </div>
                <div className='flex items-start'>
                    <div>
                        {iklan.map((ikln, index) => (
                            <img key={ikln.id} className="p-5 w-full" alt='Survey Masyarakat' src={ikln.src}/>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DetailBeritaContent;