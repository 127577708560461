import React from 'react';
import Header from "../components/Header";
import CustomeNavbar from "../components/CustomeNavbar";
import ToTop from "../components/ToTop";
import Footer from "../components/Footer";
import NotFoundContent from "../contents/NotFoundContent";

const PageNotFound = () => {
    return (
        <>
            <Header />
            <CustomeNavbar />
            <NotFoundContent />
            <ToTop />
            <Footer />
        </>
    );
};

export default PageNotFound;