import React, {useEffect, useState} from 'react';
import IconDownload from "./icons/IconDownload";
import {Link} from "react-router-dom";
import ViewIcon from "./icons/ViewIcon";
import {EyeIcon} from "@heroicons/react/20/solid";

const TablePeraturan = ({ data }) => {

    return (
        <div className="overflow-x-auto">
            <table className="table-auto min-w-full border-collapse divide-y divide-gray-200">
                <thead className="bg-gray-50">
                <tr>
                    <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                        NO
                    </th>
                    <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                        Nama File
                    </th>
                    <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                        Action
                    </th>
                </tr>
                </thead>
                {data.length > 0 ? (
                    <tbody className="bg-white divide-y divide-gray-200">
                        {data.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                <td className="px-6 py-4 whitespace-nowrap">{rowIndex+1}</td>
                                <td className="px-6 py-4 whitespace-nowrap">{row.originalName}</td>
                                <td className="flex px-6 py-4 whitespace-nowrap">
                                    <a className='inline-flex hover:text-sky-500' href={`/${row.path}`}
                                       download={`/${row.path}`}>

                                        <span className='pt-2 pl-2 pr-2'>
                                            <IconDownload className='w-5 h-5'/>
                                        </span>
                                    </a>
                                    <Link className='inline-flex hover:text-sky-500' target='_blank' rel="noopener noreferrer" to={`/${row.path}`}>
                                        <span className='pt-2 pl-2 pr-2'>
                                            <EyeIcon className='w-5 h-5' />
                                        </span>
                                    </Link>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                ) : (
                    <tbody className="bg-white divide-y divide-gray-200">
                    <tr>
                        <td>No Data</td>
                    </tr>
                    </tbody>
                )}

            </table>
        </div>
    );
};

export default TablePeraturan;