import React from 'react';
import MenuCard from "./MenuCard";

const MenuBig = () => {
    const menuItems = [
        {
            id: 1,
            title: 'Pengaduan',
            to: '/pengaduan',
            icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-12 h-12 text-blue-900">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                </svg>
            ,
            description: 'Media pelaporan segala kegiatan berindikasi pelanggaran di Kabupaten Paniai.'
        },
        {
            id: 2,
            title: 'Isi Survey',
            to: '/surveimasyarakat',
            icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-12 h-12 text-blue-900">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
                </svg>
            ,
            description: 'Survei Kepuasan Masyarakat untuk mengumpulkan pendapat dan umpan balik dari masyarakat.'
        },
        {
            id: 3,
            title: 'PKPT',
            to: '/suratinternal',
            icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-12 h-12 text-blue-900">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 3v1.5M3 21v-6m0 0l2.77-.693a9 9 0 016.208.682l.108.054a9 9 0 006.086.71l3.114-.732a48.524 48.524 0 01-.005-10.499l-3.11.732a9 9 0 01-6.085-.711l-.108-.054a9 9 0 00-6.208-.682L3 4.5M3 15V4.5" />
                </svg>
            ,
            description: 'Informasi publikasi anggaran inspektorat kab. Paniai bisa di akses dari sini!.'
        },
    ];

    return (
        <div className="grid desktop:grid-cols-3 tab:grid-cols-2 phone:grid-cols-1 gap-4 phone:gap-2 p-4">
            {menuItems.map((item) => (
                <div key={item.id} className='h-auto p-3 phone:p-1 text-center'>
                    <MenuCard title={item.title} icon={item.icon} to={item.to} description={item.description} />
                </div>
            ))}
        </div>
    );
};

export default MenuBig;