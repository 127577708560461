import React, {useEffect, useState} from 'react';
import FadeIn from "../components/FadeIn";
import Jumbotron from "../components/Jumbotron";
import axios from "axios";
import Loading from "../components/Loading";
import SlideGaleri from "../components/SlideGaleri";

const GaleriContent = () => {
    return (
        <div>
            <Jumbotron title='Galeri' />

            <FadeIn delay={0.2} direction='left' fullWidth>
                <div className='mt-10 mb-10'>
                    <SlideGaleri />
                </div>
            </FadeIn>
        </div>
    );
};

export default GaleriContent;