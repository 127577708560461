import React, {useEffect, useState} from 'react';
import axios from "axios";
import logo from '../logo.png'

const PrintPengaduan = () => {
    let [printloop, setPrintloop] = useState(false);
    const [namaPelapor, setNamaPelapor] = useState(''),
        [emailPelapor, setEmailPelapor] = useState(''),
        [phonePelapor, setPhonePelapor] = useState(''),
        [terduga, setTerduga] = useState(''),
        [jabatan , setJabatan] = useState(''),
        [unitkerja, setUnitkerja] = useState(''),
        [jenispelanggaran, setJenispelanggaran] = useState(''),
        [tempatpelanggaran, setTempatpelanggaran] = useState(''),
        [waktupelanggaran, setWaktupelanggaran] = useState(''),
        [kronologi, setKronologi] = useState('');

    useEffect(  () => {
        document.addEventListener('keydown', function(event) {
            if (event.ctrlKey && (event.key === 'p' || event.keyCode === 80)) {
                event.preventDefault();
                alert('Gunakan Button Print dibawah!');
            }
        });
        const searchParams = new URL(window.location.href);
        getQueryURL(searchParams).then((id) => {
            fetchData(id).then((res) => {
                const pengaduan = res.data;
                setNamaPelapor(pengaduan.nama_pelapor);
                setEmailPelapor(pengaduan.email_pelapor);
                setPhonePelapor(pengaduan.nomor_telepon_pelapor);
                setTerduga(pengaduan.nama_terduga);
                setJabatan(pengaduan.jabatan_terduga);
                setUnitkerja(pengaduan.unit_kerja);
                setJenispelanggaran(pengaduan.jenis_pelanggaran);
                setTempatpelanggaran(pengaduan.tempat_pelanggaran);

                let tanggal = pengaduan.waktu_pelanggaran.split('T')[0];
                let jam = pengaduan.waktu_pelanggaran.split('T')[1];
                let timeLocal = tanggal.split('-')[2] + '/' + tanggal.split('-')[1] + '/' + tanggal.split('-')[0] + ' Pukul ' + jam;

                setWaktupelanggaran(timeLocal);
                setKronologi(pengaduan.kronologi);
                setPrintloop(true);

            }).catch(err => {

            })
        })
    }, []);

    const getQueryURL = (searchParam) => {
        return new Promise((resolve, reject) => {
            let id = '';
            searchParam.searchParams.forEach((value, name, searchParams) => {
                id = value;
            })
            resolve(id);
        })
    }

    const fetchData = (id) => {
        return new Promise(async (resolve, reject) => {
            await axios.get('/panel/items/pengaduan/' + id).then((res) => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            })
        })
    }

    const printHandle = (e) => {
        let eleBtnPrint = document.getElementById('elementBtnPrint');
        setTimeout(() => {
            eleBtnPrint.remove();
            window.print();
        }, 2000)
    }

    return (
        <div className='ml-20 mr-20'>
            <div className='flex flex-row'>
                <div className='mt-5'>
                    <img alt='logo' src={logo} className='w-[70px]'/>
                </div>
                <div className='m-5 text-center font-bold w-[100%]'>
                    <h1 className=''>INSPEKTORAT DAERAH</h1>
                    <h1 className=''>KABUPATEN PANIAI</h1>
                    <h1 className=''>PROVINSI PAPUA TENGAH</h1>
                </div>
            </div>

            <hr/>

            {(namaPelapor === '' ? (
                <div>
                    <h1>Data tidak ditemukan!</h1>
                </div>
            ): (
                <div>
                    <div>
                        <h1 className='text-3xl pt-5'>Detail Pelapor</h1>
                        <h1 className='pt-5'>Nama: {namaPelapor}</h1>
                        <h1 className='pt-5'>Email: {emailPelapor}</h1>
                        <h1 className='pt-5 pb-2'>Telepon: {phonePelapor}</h1>
                    </div>

                    <hr/>

                    <div>
                        <h1 className='text-3xl pt-5'>Detail Terduga</h1>
                        <h1 className='pt-5'>Nama: <span className='font-bold'>{terduga}</span></h1>
                        <h1 className='pt-5'>Jabatan: {jabatan}</h1>
                        <h1 className='pt-5'>Unit Kerja: {unitkerja}</h1>
                        <h1 className='pt-5'>Jenis Pelanggaran: {jenispelanggaran}</h1>
                        <h1 className='pt-5'>Tempat: {tempatpelanggaran}</h1>
                        <h1 className='pt-5'>Waktu: {waktupelanggaran}</h1>
                        <h1 className='pt-5'>Kronologi: </h1>
                        <div className='border-2 p-2'>
                            <h1 className=''>{kronologi}</h1>
                        </div>
                    </div>

                    <div className='m-10 flex items-center justify-center' id='elementBtnPrint'>
                        <button className='text-center border p-2 rounded-lg hover:bg-blue-500 hover:text-white' onClick={printHandle}>Print</button>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default PrintPengaduan;