import React, {useEffect, useState} from 'react';
import Jumbotron from "../components/Jumbotron";
import {EyeIcon} from "@heroicons/react/20/solid";
import axios from "axios";
import ModalLacakPengaduan from "../components/ModalLacakPengaduan";
import {viewAlert} from "../SweetAlert/SweetAlert";

const LacakPengaduanContent = () => {
    const [listPengaduan, setListPengaduan] = useState([]);
    const [id, setId] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [dataComplain, setDataComplain] = useState({});

    useEffect(() => {
        const datack = JSON.parse(getCookies('ID'))
        setListPengaduan(datack);
    }, [])

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleLacakButton = async (id) => {
        setDataComplain({});
        if (id === '') {
            viewAlert('Masalah!', 'ID Pengaduan tidak boleh kosong!', 'error')
        } else {
            await getDataComplaint(id).then((data) => {
                console.log(data);
                setDataComplain(data)
                openModal();
            }).catch((err) => viewAlert('Masalah!', err, 'error'))
        }
    }

    const getDataComplaint = (id) => {
        return new Promise(async (resolve, reject) => {
            await axios.get('/api/complaint/check/' + id).then((response) => {
                resolve(response.data)
            }).catch((error) => reject(error))
        })
    }

    const getCookies = (name) => {
        const nameEQ = name + "=";
        const cookies = document.cookie.split(';');

        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i];

            // Trim any leading whitespace characters
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }

            // If the cookie name matches, return its value
            if (cookie.indexOf(nameEQ) === 0) {
                return cookie.substring(nameEQ.length, cookie.length);
            }
        }

        // Return null if the cookie was not found
        return null;
    }

    const handleChangeId = (id) => {
        setId(id);
    }

    const submitLacak = () => {
        handleLacakButton(id)
    }

    return (
        <div>
            <Jumbotron title='Lacak Pengaduan'/>
            <div className='flex flex-col items-center justify-center mt-10'>
                <section className='body-font max-w-7xl mt-5 ml-5 mr-5'>
                    <div className='flex flex-row items-center justify-center pb-10'>
                        <input onChange={(e) => handleChangeId(e.target.value)} className='p-2 w-80 border-2 rounded-lg' placeholder='Masukkan ID Pengaduan' />
                        <button onClick={submitLacak} className='bg-blue-950 text-white ml-5 p-2 rounded-lg'>Lacak</button>
                    </div>
                </section>

                <section className='body-font max-w-7xl bg-gray-200 mt-5 mb-20'>
                    <div className='flex items-center justify-center m-10'>
                        <h1 className='text-2xl'>Riwayat Pengaduan</h1>
                    </div>
                    <div className="overflow-x-auto">
                        <table className="min-w-full bg-white border border-gray-200">
                            <thead>
                            <tr>
                                <th className="py-2 px-4 border-b">ID Pengaduan</th>
                                <th className="py-2 px-4 border-b">Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {listPengaduan !== null ? (
                                <>
                                    {listPengaduan.map((item) => (
                                        <tr key={item}>
                                            <td className="py-2 px-4 border-b">{item}</td>
                                            <td className="py-2 px-4 border-b">
                                                <button onClick={() => handleLacakButton(item)} className='hover:text-blue-400'>
                                                    <div className="flex items-center justify-center">
                                                        <EyeIcon className='w-4 h-4'/>
                                                        <p className='ml-2'>Lacak</p>
                                                    </div>
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </>
                            ) : (
                                <div className='flex items-center justify-center'>
                                    Riwayat Kosong
                                </div>
                            )}
                            </tbody>
                        </table>
                    </div>
                </section>

                <section className='body-font max-w-7xl mb-20 ml-5 mr-5'>
                    <p>ID pengaduan ini akan tersimpan di riwayat pengaduan selama 7 hari dari pengaduan terakhir
                        di buat.</p>
                    <p>Di anjurkan untuk mencatat ID pelanggan ini!.</p>
                    <p>Gunakan Pencarian untuk melacak langsung menggunakan ID Pengaduan anda!.</p>
                </section>
            </div>

            <ModalLacakPengaduan isOpen={isModalOpen} onClose={closeModal} events={dataComplain} />
        </div>
    );
};

export default LacakPengaduanContent;