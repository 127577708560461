import React from 'react';
import jumbotronBg from '../assets/slide1.jpeg';

const Jumbotron = ({title}) => {
    return (
        <div className="bg-blue-950">
            <div
                className="bg-cover bg-center py-32"
                style={{ backgroundImage: `url(${jumbotronBg})`, opacity: 0.2}}
            >

                <div className="mx-auto container text-center">
                    <h1 className="desktop:text-8xl phone:text-5xl font-bold text-white z-50">{title}</h1>
                </div>
            </div>
        </div>
    );
};

export default Jumbotron;