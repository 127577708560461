import React, { useState } from 'react';
import axios from "axios";
import {viewAlert} from "../SweetAlert/SweetAlert";

const CommentForm = () => {
    const [message, setComment] = useState('');
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [button, setButton] = useState('Submit');

    const handleCommentChange = (e) => {
        setComment(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const commentObject = {
            name: name,
            email: email,
            message: message
        };
        await postComment(commentObject);
        // You can add logic here to handle the comment submission
    };

    // Post data using axios
    const postComment = async (commentObject) => {
        try {
            setButton('Submitting...');
            const response = await axios.post(
                '/api/feedback',
                commentObject,
            );
            // const response = await axios.get(
            //     '/v1/ping');
            if (response.status === 200 || response.status === 201) {
                resetForm();
                viewAlert('success', 'Success', 'Komentar anda berhasil dikirimkan');
            } else {
                throw new Error(response.status);
            }
        } catch (error) {
            console.log(error);
            viewAlert('error', 'Error', 'Komentar anda gagal dikirimkan');
            // resetForm();
        }
    }

    const resetForm = () => {
        setComment('');
        setEmail('');
        setName('');
        setButton('Submit');
    }

    return (
        <div className="p-4">
            <label htmlFor="comment" className="block text-gray-600 text-xl font-medium mb-4">
                Berikan Komentar / Masukan Anda!
            </label>
            <label htmlFor="email" className="block text-left text-gray-600 text-sm font-medium">
                Name:
            </label>
            <input value={name} onChange={(e) => setName(e.target.value)} type='text' className='mt-1 p-1 w-full border border-gray-300 rounded-md focus:ring focus:ring-blue-300'/>
            <label htmlFor="email" className="block text-left text-gray-600 text-sm font-medium">
                Email:
            </label>
            <input value={email} onChange={(e) => setEmail(e.target.value)} type='text' className='mt-1 p-1 w-full border border-gray-300 rounded-md focus:ring focus:ring-blue-300'/>
            <label htmlFor="comment" className="block text-left text-gray-600 text-sm font-medium">
                Komentar:
            </label>
            <textarea
                id="comment"
                name="comment"
                value={message}
                onChange={handleCommentChange}
                className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:ring focus:ring-blue-300"
                rows="3"
            ></textarea>
            <button
                type="submit"
                className="mt-2 bg-blue-900 text-white py-2 px-4 rounded hover:bg-blue-700 focus:outline-none"
                onClick={handleSubmit}
            >
                {button}
            </button>
        </div>
    );
};

export default CommentForm;
