import React from 'react';
import {useParams} from "react-router-dom";
import { Worker, Viewer } from '@react-pdf-viewer/core';

const DocumentPreview = () => {
    const { id} = useParams();

    return (
        <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
            <div className='w-[100%] h-[100%]'>
                <Viewer fileUrl={`/api/file/download/${id}`} />
            </div>
        </Worker>
    );
};

export default DocumentPreview;