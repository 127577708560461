import React, {useEffect, useRef, useState} from 'react'
import logo from '../logo.png'
import FadeIn from './FadeIn'
import Marquee from "./Marquee";
import axios from "axios";
import {viewAlert} from "../SweetAlert/SweetAlert";
import getSettingsList from "../api/settings";

const Header = () => {
    const [alamat, setAlamat] = useState(''),
        [telp, setTelp] = useState(''),
        [email, setEmail] = useState('')

    const isMounted = useRef(false);

    useEffect(() => {
        if (isMounted.current) {
            return;
        }

        isMounted.current = true;

        getSettingsList().then(res => {
            getLocalSettings(res);
        })

        return () => {

        }
    });

    const getLocalSettings = (listSettings) => {
        listSettings.forEach((item) => {
            let tempadress = '';
            let temptelp = '';
            let tempemail = '';

            if (item.key === 'address') {
                tempadress = item.value;
                setAlamat(tempadress);
            }

            if (item.key === 'telp') {
                temptelp = item.value;
                setTelp(temptelp);
            }

            if (item.key === 'email') {
                tempemail = item.value;
                setEmail(tempemail);
            }
        })
    }

  return (
    <>
        <div>
            <Marquee />
        </div>
        <div className='mx-auto max-w-8xl tab:px-6 phone:px-2 desktop:px-9 m-10'>
            {/* Brand */}
            <div className='relative flex h-16 items-center justify-between'>
                <div className='absolute inset-y-0 left-0 flex items-center justify-start'>
                    <FadeIn delay={0.2} direction="down" padding fullWidth>
                        <div className='flex items-center'>
                            <img alt='img' src={logo} className='h-12 w-auto'/>
                            <div className='ml-5'>
                                <strong className='desktop:text-2xl tab:text-sky-800 phone:text-sm phone:text-sky-800'>Inspektorat
                                    Daerah</strong><br/>
                                <span className='desktop:text-lg phone:text-sm'>Kabupaten Paniai</span>
                            </div>

                        </div>
                    </FadeIn>
                </div>

                <div className='flex items-center justify-between'>

                </div>

                <div className='desktop:flex desktop:justify-end phone:hidden tab:hidden'>
                    <div className='flex items-center'>
                        <div className='flex items-center'>
                            <FadeIn delay={0.3} direction="down" padding fullWidth>
                                <div className='bg-blue-900 p-1 rounded-md'>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                         className='h-5 w-auto text-white'>
                                        <path fillRule="evenodd"
                                              d="M4.5 2.25a.75.75 0 000 1.5v16.5h-.75a.75.75 0 000 1.5h16.5a.75.75 0 000-1.5h-.75V3.75a.75.75 0 000-1.5h-15zM9 6a.75.75 0 000 1.5h1.5a.75.75 0 000-1.5H9zm-.75 3.75A.75.75 0 019 9h1.5a.75.75 0 010 1.5H9a.75.75 0 01-.75-.75zM9 12a.75.75 0 000 1.5h1.5a.75.75 0 000-1.5H9zm3.75-5.25A.75.75 0 0113.5 6H15a.75.75 0 010 1.5h-1.5a.75.75 0 01-.75-.75zM13.5 9a.75.75 0 000 1.5H15A.75.75 0 0015 9h-1.5zm-.75 3.75a.75.75 0 01.75-.75H15a.75.75 0 010 1.5h-1.5a.75.75 0 01-.75-.75zM9 19.5v-2.25a.75.75 0 01.75-.75h4.5a.75.75 0 01.75.75v2.25a.75.75 0 01-.75.75h-4.5A.75.75 0 019 19.5z"
                                              clipRule="evenodd"/>
                                    </svg>
                                </div>
                                <div className='ml-5'>
                                    <strong className='text-sm text-sky-800'>{alamat}</strong><br/>
                                    <span className='text-sm text-gray-400'>Kab. Paniai</span>
                                </div>
                            </FadeIn>
                        </div>
                    </div>
                    {/*<div className='justify-between p-5'>|</div>*/}
                    <div className='flex items-center'>
                        <div className='flex items-center'>
                            <FadeIn delay={0.3} direction="down" padding fullWidth>
                                <div className='bg-blue-900 p-1 rounded-md'>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                         className="h-5 w-auto text-white">
                                        <path fillRule="evenodd"
                                              d="M19.5 9.75a.75.75 0 01-.75.75h-4.5a.75.75 0 01-.75-.75v-4.5a.75.75 0 011.5 0v2.69l4.72-4.72a.75.75 0 111.06 1.06L16.06 9h2.69a.75.75 0 01.75.75z"
                                              clipRule="evenodd"/>
                                        <path fillRule="evenodd"
                                              d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
                                              clipRule="evenodd"/>
                                    </svg>
                                </div>

                                <div className='ml-5'>
                                    <strong className='text-sm text-sky-800'>No. Telp</strong><br/>
                                    <span className='text-md text-gray-400'>{telp}</span>
                                </div>
                            </FadeIn>
                        </div>
                    </div>
                    {/*<div className='justify-between'>|</div>*/}
                    <div className='flex items-center'>
                        <div className='flex items-center'>
                            <FadeIn delay={0.3} direction="down" padding fullWidth>
                                <div className='bg-blue-900 p-1 rounded-md'>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                         className="h-5 w-auto text-white">
                                        <path fillRule="evenodd"
                                              d="M17.834 6.166a8.25 8.25 0 100 11.668.75.75 0 011.06 1.06c-3.807 3.808-9.98 3.808-13.788 0-3.808-3.807-3.808-9.98 0-13.788 3.807-3.808 9.98-3.808 13.788 0A9.722 9.722 0 0121.75 12c0 .975-.296 1.887-.809 2.571-.514.685-1.28 1.179-2.191 1.179-.904 0-1.666-.487-2.18-1.164a5.25 5.25 0 11-.82-6.26V8.25a.75.75 0 011.5 0V12c0 .682.208 1.27.509 1.671.3.401.659.579.991.579.332 0 .69-.178.991-.579.3-.4.509-.99.509-1.671a8.222 8.222 0 00-2.416-5.834zM15.75 12a3.75 3.75 0 10-7.5 0 3.75 3.75 0 007.5 0z"
                                              clipRule="evenodd"/>
                                    </svg>
                                </div>

                                <div className='ml-5'>
                                    <strong className='text-sm text-sky-800'>Email</strong><br/>
                                    <span className='text-md text-gray-400'>{email}</span>
                                </div>
                            </FadeIn>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr/>
    </>
  )
}

export default Header