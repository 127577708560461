import React, {useEffect} from 'react';
import Header from "../components/Header";
import CustomeNavbar from "../components/CustomeNavbar";
import PengaduanContent from "../contents/PengaduanContent";
import ToTop from "../components/ToTop";
import Footer from "../components/Footer";
import LacakPengaduanContent from "../contents/LacakPengaduanContent";

const LacakPengaduan = () => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        scrollToTop();
    }, []);
    return (
        <div>
            <Header />
            <CustomeNavbar />
            <LacakPengaduanContent />
            <ToTop />
            <Footer />
        </div>
    );
};

export default LacakPengaduan;