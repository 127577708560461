import React from 'react';
import Collapsible from "../components/Collapsible";
import FadeIn from "../components/FadeIn";
import FadeInMenu from "../components/FadeInMenu";
import Jumbotron from "../components/Jumbotron";

const TugasFungsiContent = () => {

    return (
        <div className='text-gray-600'>
            {/*<section className='relative w-[100%] h-[200px] items-center justify-center'>*/}
            {/*    <div className="absolute inset-0 flex items-center justify-center">*/}
            {/*        <p className="text-gray-800 text-5xl font-bold">Tugas & Fungsi</p>*/}
            {/*    </div>*/}
            {/*</section>*/}
            <Jumbotron title='Tugas & Fungsi' />

            <FadeIn delay={0.5} direction="left" padding fullWidth>
                <section
                    className="body-font mt-10 desktop:ml-[20%] desktop:mr-[20%] tab:ml-[10%] tab:mr-[10%] phone:ml-5 phone:mr-5">
                    <p className="text-lg font-bold text-center mb-5">Tugas & Fungsi</p>
                    <p>Sesuai Peraturan Bupati Nomor 5 Tahun 2023 tentang Perubahan Atas Peraturan Bupati Nomor 101
                        Tahun 2021 tentang Kedudukan, Susunan Organisasi, Tugas dan Fungsi serta Tata Kerja Inspektorat
                        Daerah, Inspektorat Daerah sebagai aparat pengawas fungsional yang berada di bawah dan
                        bertanggung jawab kepada Bupati memiliki tugas dan fungsi sebagai berikut (klik untuk melihat
                        selengkapnya):</p>
                </section>
            </FadeIn>

            <section className="body-font mt-5 mb-10 desktop:ml-[20%] desktop:mr-[20%] tab:ml-[10%] tab:mr-[10%]">
                <Collapsible title='Inspektur Daerah'>
                    <div className='ml-5'>
                        <div className='mb-4 p-3'>
                            <p>Inspektur Daerah mempunyai tugas membantu Bupati dalam membina dan mengawasi pelaksanaan
                                urusan pemerintahan yang menjadi kewenangan Daerah dan Tugas Pembantuan oleh Perangkat
                                Daerah. Inspektur Daerah mempunyai fungsi:</p>
                        </div>
                        <div className="ml-10">
                            <ol className="list-decimal">
                                <li>Perumusan kebijakan teknis bidang pengawasan dan fasilitasi pengawasan.</li>
                                <li>Pelaksanaan pengawasan internal terhadap kinerja dan keuangan melalui audit, reviu,
                                    evaluasi, pemantauan, dan kegiatan pengawasan lainnya.
                                </li>
                                <li>Pelaksanaan pengawasan untuk tujuan tertentu atas penugasan Bupati dan/atau Gubernur
                                    sebagai wakil Pemerintah Pusat.
                                </li>
                                <li>Penyusunan laporan hasil pengawasan.</li>
                                <li>Pelaksanaan koordinasi pencegahan tindak pidana korupsi.</li>
                                <li>Penyelenggaraan dan pengawasan reformasi birokrasi, pelayanan publik dan Sistem
                                    Akuntabilitas Kinerja Instansi Pemerintah (SAKIP).
                                </li>
                                <li>Pelaksanaan administrasi Inspektorat Daerah.</li>
                                <li>Pelaksanaan tugas lain yang diberikan oleh Bupati terkait dengan tugas dan
                                    fungsinya.
                                </li>
                            </ol>
                        </div>
                    </div>
                </Collapsible>
                <hr/>
                <Collapsible title='Sekretariat'>
                    <div className='ml-5'>
                        <div>
                            <p className='mb-3'>Sekretariat dipimpin oleh Sekretaris yang berada di bawah dan
                                bertanggung jawab kepada Inspektur Daerah.</p>
                            <p className='mb-3'>Sekretaris mempunyai tugas merencanakan, menyiapkan, merumuskan,
                                mengordinasikan pelaksanakan kebijakan pengorganisasian, ketatausahaan, keuangan,
                                aset/barang, kepegawaian, hukum, organisasi dan tata laksana, reformasi birokrasi,
                                pelayanan publik, Sistem Akuntabilitas Kinerja Instansi Pemerintah (SAKIP) pemantauan,
                                evaluasi, pelaporan, kerumahtanggaan, kearsipan, kerja sama, kehumasan, Sistem
                                Pengendalian Instansi Pemerintah (SPIP), informasi dan dokumentasi serta melaksanakan
                                pembinaan dan pemberian dukungan administrasi kepada seluruh unit organisasi di
                                lingkungan Inspektorat Daerah.</p>
                            <p>Sekretaris mempunyai fungsi:</p>
                        </div>
                        <div className="ml-10 mb-5 mt-2">
                            <ol className="list-decimal">
                                <li>Penyiapan bahan kegiatan di lingkungan Inspektorat Daerah.</li>
                                <li>Penyusunan rencana program dan kegiatan di lingkungan Inspektorat Daerah.</li>
                                <li>Pengoordinasian, pembinaan dan pemberian dukungan administrasi ketatausahaan,
                                    kepegawaian, hukum, organisasi dan tatalaksana, perencanaan, keuangan,
                                    kerumahtanggaan, kerja sama, kehumasan, kearsipan, evaluasi dan pelaporan, serta
                                    informasi dan dokumentasi.
                                </li>
                                <li>Pengoordinasian penyelesaian tindak lanjut hasil pemeriksaan.</li>
                                <li>Pengoordinasian, pembinaan dan pelaksanaan pelayanan publik, reformasi birokrasi dan
                                    Sistem Akuntabilitas Kinerja Instansi Pemerintah (SAKIP), Sistem Pengendalian Intern
                                    Pemerintah (SPIP) pada Inspektorat Daerah.
                                </li>
                                <li>Penyiapan bahan pengelolaan aset/kekayaan/ barang milik daerah serta penyusunan
                                    kebutuhan barang dan rencana pemeliharaan barang dan pelayanan pengadaan barang/jasa
                                    di lingkungan Inspektorat Daerah.
                                </li>
                                <li>Pengoordinasian perumusan usulan analisis jabatan, peta jabatan, analisis beban
                                    kerja, evaluasi jabatan dan standar kompetensi jabatan.
                                </li>
                                <li>Pelaksanaan pemantauan evaluasi dan pelaporan kegiatan kesekretariatan sesuai
                                    ketentuan yang berlaku.
                                </li>
                                <li>Pelaksanaan tugas lain yang diberikan oleh Inspektur Daerah sesuai dengan tugas dan
                                    fungsinya.
                                </li>
                            </ol>
                        </div>
                        <div className="">
                            <p>Sekretariat sendiri terdiri dari 2 Subbagian yang bertanggung jawab kepada Sekretaris,
                                yakni:</p>
                            <div className="ml-5">
                                <ol className="list-disc mt-2">
                                    <li>Subbagian Evaluasi dan Pelaporan, dipimpin Kepala Subbagian yang berada di bawah
                                        dan bertanggung jawab kepada Sekretaris mempunyai tugas:
                                    </li>
                                </ol>

                                <ol className="list-decimal ml-4 mt-2">
                                    <li>Menyiapkan bahan pengumpulan, pendokumentasian dan tindak lanjut laporan hasil
                                        pengawasan.
                                    </li>
                                    <li>Menyiapkan bahan pengolahan data hasil pengawasan.</li>
                                    <li>Menyiapkan bahan administrasi evaluasi penanganan pengaduan masyarakat.</li>
                                    <li>Menyiapkan bahan penyelesaian/ pemantauan tindak lanjut hasil pemeriksaan Aparat
                                        Pengawas Fungsional, Badan Pemeriksa Keuangan, Inspektorat Provinsi serta Badan
                                        Pemeriksa Keuangan dan Pembangunan (BPKP) serta tugas-tugas madatori dari
                                        Pemerintah Pusat.
                                    </li>
                                    <li>Menyiapkan bahan penyusunan ikhtisar hasil pengawasan sementara.</li>
                                    <li>Menyiapkan bahan penilaian internal pembangunan Zona Integritas.</li>
                                    <li>Menyiapkan bahan gelar pengawasan daerah.</li>
                                    <li>Melakukan tugas lain yang diberikan oleh pimpinan sesuai tugas dan fungsinya.
                                    </li>
                                </ol>
                            </div>
                        </div>

                        <div>
                            <div className="ml-5">
                                <ol className="list-disc mt-2">
                                    <li>Subbagian Administrasi dan Umum, dipimpin Kepala Subbagian yang berada di bawah
                                        dan bertanggung jawab kepada Sekretaris mempunyai tugas:
                                    </li>
                                </ol>

                                <ol className="list-decimal ml-4 mt-2">
                                    <li>Menyiapkan bahan pengelolaan ketatausahaan, keuangan, kepegawaian dan
                                        kerumahtanggaan.
                                    </li>
                                    <li>Menyiapkan bahan pengelolaan peningkatan Kapabilitas Aparat Pengawas Internal
                                        Pemerintah.
                                    </li>
                                    <li>Menyiapkan bahan pengelolaan aset/kekayaan/barang milik daerah, serta penyusunan
                                        rencana kebutuhan dan pemeliharaan barang milik daerah.
                                    </li>
                                    <li>Menyiapkan bahan koordinasi, pembinaan dan pelaksanaan kerjasama, kehumasan,
                                        hukum, organisasi dan tata laksana, kearsipan, reformasi birokrasi dan pelayanan
                                        publik, Sistem Akuntabilitas Kinerja Instansi Pemerintah (SAKIP), reformasi
                                        birokrasi serta pengelolaan informasi dan dokumentasi pada Inspektorat Daerah.
                                    </li>
                                    <li>Melakukan tugas lain yang diberikan oleh pimpinan sesuai tugas dan fungsinya.
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </Collapsible>
                <hr/>
                <Collapsible title='Inspektur Pembantu I Bidang Pemerintahan, Hukum dan Aparatur'>
                    <div className="ml-5">
                        <div>
                            <p className="mb-2">Inspektur Pembantu I Bidang Pemerintahan, Hukum dan Aparatur dipimpin
                                oleh Inspektur Pembantu berada di bawah dan bertanggung jawab kepada Inspektur
                                Daerah.</p>
                            <p className="mb-2">Inspektur Pembantu I Bidang Pemerintahan, Hukum dan Aparatur mempunyai
                                tugas membantu Inspektur Daerah dalam melaksanakan pembinaan dan pengawasan terhadap
                                penyelenggaraan pemerintahan Daerah, pemerintahan desa, hukum dan aparatur.</p>
                            <p className="mb-2">Inspektur Pembantu I Bidang Pemerintahan, Hukum dan Aparatur mempunyai
                                fungsi:</p>
                        </div>
                        <div className="ml-4">
                            <ol className="list-decimal">
                                <li>Pembinaan dan pengawasan terhadap penyelenggaraan pemerintahan Daerah, pemerintahan
                                    desa, hukum dan aparatur.
                                </li>
                                <li>Penganalisaan data yang menjadi obyek pemeriksaan bidang pemerintahan, hukum dan
                                    aparatur.
                                </li>
                                <li>Pelaksanaan pemeriksaan, pengusutan, pengujian dan penilaian tugas pengawasan bidang
                                    pemerintahan, hukum dan aparatur.
                                </li>
                                <li>Pelaksanaan tugas lain sesuai tugas dan fungsinya.</li>
                            </ol>
                        </div>
                    </div>
                </Collapsible>
                <hr/>
                <Collapsible
                    title='Inspektur Pembantu II Bidang Perekonomian, Pendapatan, Pengelolaan Keuangan dan Aset Daerah'>
                    <div className="ml-5">
                        <div>
                            <p className="mb-2">Inspektur Pembantu II Bidang Perekonomian, Pendapatan, Pengelolaan
                                Keuangan dan Aset Daerah dipimpin oleh Inspektur Pembantu berada di bawah dan
                                bertanggung jawab kepada Inspektur Daerah.</p>
                            <p className="mb-2">Inspektur Pembantu II Bidang Perekonomian, Pendapatan, Pengelolaan
                                Keuangan dan Aset Daerah mempunyai tugas membantu Inspektur Daerah dalam melaksanakan
                                pengawasan terhadap pendapatan, pengelolaan keuangan dan aset Daerah.</p>
                            <p className="mb-2">Inspektur Pembantu II Bidang Perekonomian, Pendapatan, Pengelolaan
                                Keuangan dan Aset Daerah mempunyai fungsi:</p>
                        </div>
                        <div className="ml-4">
                            <ol className="list-decimal">
                                <li>Pembinaan dan pengawasan terhadap perekonomian, pendapatan, pengelolaan keuangan dan
                                    aset Daerah.
                                </li>
                                <li>Penganalisaan data yang menjadi obyek pemeriksaan bidang perekonomian, pendapatan,
                                    pengelolaan keuangan dan aset Daerah.
                                </li>
                                <li>Pelaksanaan pemeriksaan, pengusutan, pengujian dan penilaian tugas pengawasan bidang
                                    perekonomian, pendapatan, pengelolaan keuangan dan aset Daerah.
                                </li>
                                <li>Pelaksanaan tugas lain sesuai tugas dan fungsinya.</li>
                            </ol>
                        </div>
                    </div>
                </Collapsible>
                <hr/>
                <Collapsible title='Inspektur Pembantu III Bidang Pertanian, Pembangunan dan Lingkungan Hidup'>
                    <div className="ml-5">
                        <div>
                            <p className="mb-2">Inspektur Pembantu III Bidang Pertanian, Pembangunan dan Lingkungan
                                Hidup dipimpin oleh Inspektur Pembantu, berada di bawah dan bertanggung jawab kepada
                                Inspektur Daerah.</p>
                            <p className="mb-2">Inspektur Pembantu III Bidang Pertanian, Pembangunan dan Lingkungan
                                Hidup mempunyai tugas membantu Inspektur Daerah dalam melaksanakan pengawasan di bidang
                                pertanian, pembangunan dan lingkungan hidup.</p>
                            <p className="mb-2">Inspektur Pembantu III Bidang Pertanian, Pembangunan dan Lingkungan
                                Hidup mempunyai fungsi:</p>
                        </div>
                        <div className="ml-4">
                            <ol className="list-decimal">
                                <li>Pembinaan dan pengawasan terhadap pertanian, pembangunan dan lingkungan hidup.</li>
                                <li>Penganalisaan data yang menjadi obyek pemeriksaan bidang pertanian, pembangunan dan
                                    lingkungan hidup.
                                </li>
                                <li>Pelaksanaan pengawasan, pemeriksaan, pengusutan, pengujian dan penilaian tugas
                                    bidang pertanian, pembangunan dan lingkungan hidup.
                                </li>
                                <li>Pelaksanaan tugas lain sesuai tugas dan fungsinya.</li>
                            </ol>
                        </div>
                    </div>
                </Collapsible>
                <hr/>
                <Collapsible title='Inspektur Pembantu IV Bidang Kesehatan, Pendidikan dan Kesejahteraan Rakyat'>
                    <div className="ml-5">
                        <div>
                            <p className="mb-2">Inspektur Pembantu IV Bidang Kesehatan, Pendidikan dan Kesejahteraan
                                Rakyat dipimpin oleh Inspektur Pembantu berada di bawah dan bertanggung jawab kepada
                                Inspektur Daerah.</p>
                            <p className="mb-2">Inspektur Pembantu IV Bidang Kesehatan, Pendidikan dan Kesejahteraan
                                Rakyat mempunyai tugas membantu Inspektur Daerah dalam melaksanakan pengawasan di bidang
                                kesehatan, pendidikan dan kesejahteraan rakyat.</p>
                            <p className="mb-2">Inspektur Pembantu IV Bidang Kesehatan, Pendidikan dan Kesejahteraan
                                Rakyat mempunyai fungsi:</p>
                        </div>
                        <div className="ml-4">
                            <ol className="list-decimal">
                                <li>Pembinaan dan pengawasan terhadap kesehatan, pendidikan dan kesejahteraan rakyat.
                                </li>
                                <li>Penganalisaan data yang menjadi obyek pemeriksaan bidang kesehatan, pendidikan dan
                                    kesejahteraan rakyat.
                                </li>
                                <li>Pelaksanaan pemeriksaan, pengusutan, pengujian dan penilaian tugas pengawasan bidang
                                    kesehatan, pendidikan dan kesejahteraan rakyat.
                                </li>
                                <li>Pelaksanaan tugas lain sesuai tugas dan fungsinya.</li>
                            </ol>
                        </div>
                    </div>
                </Collapsible>
                <hr/>
                <Collapsible title='Inspektur Pembantu Khusus'>
                    <div className="ml-5">
                        <div>
                            <p className="mb-2">Inspektur Pembantu Khusus dipimpin oleh Inspektur Pembantu berada di
                                bawah dan bertanggung jawab kepada Inspektur Daerah.</p>
                            <p className="mb-2">Inspektur Pembantu Khusus mempunyai tugas membantu Inspektur Daerah
                                dalam melaksanakan pembinaan dan pengawasan terhadap pelaksanaan koordinasi pencegahan
                                tindak pidana korupsi, reformasi birokrasi, pelayanan publik, Sistem Akuntabilitas
                                Kinerja Instansi Pemerintah (SAKIP), dan Sistem Pengendalian Internal Pemerintah
                                (SPIP).</p>
                            <p className="mb-2">Inspektur Pembantu Khusus mempunyai fungsi:</p>
                        </div>
                        <div className="ml-4">
                            <ol className="list-decimal">
                                <li>Penyusunan perencanaan, pembinaan dan pengawasan, pencegahan tindak pidana korupsi,
                                    reformasi birokrasi, pelayanan publik, Sistem Akuntabilitas Kinerja Instansi
                                    Pemerintah (SAKIP), dan Sistem Pengendalian Internal Pemerintah (SPIP).
                                </li>
                                <li>Pelaksanaan pengawasan dengan tujuan tertentu atas penugasan dari Bupati.</li>
                                <li>Pelaksanaan koordinasi pencegahan tindak pidana korupsi dan reformasi birokrasi.
                                </li>
                                <li>Pelaksanaan tugas lain sesuai tugas dan fungsinya.</li>
                            </ol>
                        </div>
                    </div>
                </Collapsible>
                <hr/>
            </section>
        </div>
    );
};

export default TugasFungsiContent;