import React, {useEffect} from 'react';
import Header from "../components/Header";
import CustomeNavbar from "../components/CustomeNavbar";
import ToTop from "../components/ToTop";
import Footer from "../components/Footer";
import GaleriContent from "../contents/GaleriContent";

const Galeri = () => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        scrollToTop();
    }, []);
    return (
        <div>
            <Header/>
            <CustomeNavbar/>
            <GaleriContent />
            <ToTop/>
            <Footer/>
        </div>
    );
};

export default Galeri;