import React from 'react';
import {Link} from "react-router-dom";

const KegiatanList = ({kegiatan, onNewClick}) => {
    return (
        <div>
            {kegiatan.map(item => (
                <div key={item.id} className="max-w-sm bg-white rounded-xl overflow-hidden shadow-lg mb-5">
                    <img className="w-full h-48 object-cover object-center"
                         src={item.image === "" ? "https://via.placeholder.com/500" : `${item.image}`} alt="Berita"/>
                    <div className="p-6">
                        <h2 className="text-xl font-bold text-gray-800 truncate">{item.title}</h2>
                        <p className="mt-2 text-gray-600 truncate">{item.description}</p>
                        <div className="mt-4">
                            <Link className="text-blue-500 font-semibold hover:text-blue-700" to={`/berita/${item.slug}`}>Baca Selengkapnya</Link>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default KegiatanList;