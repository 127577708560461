import React from 'react';

const ModalLacakPengaduan = ({ isOpen, onClose, events }) => {
    return (
        <div>
            {isOpen && (
                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div
                        className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 transition-opacity">
                            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                        </div>
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"
                              aria-hidden="true">&#8203;</span>
                        <div
                            className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div className="flex items-center justify-center">
                                    <div className="mt-3 text-center sm:ml-4 sm:text-left p-5">
                                        <h3 className="text-2xl leading-6 font-medium text-gray-900 mb-6"
                                            id="modal-title">Pengaduan Ditemukan</h3>
                                        <hr />
                                        <div className="mt-2 mb-2">
                                            {events.id !== undefined ? (
                                                <div>
                                                    <table className='w-80 p-5'>
                                                        <thead></thead>
                                                        <tbody>
                                                        <tr>
                                                            <td className='text-right'>Pelapor</td>
                                                            <td>:</td>
                                                            <td className='text-left'>{events.name}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='text-right'>Status</td>
                                                            <td>:</td>
                                                            <td className='text-left'>{events.status}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='text-right'>Terlapor</td>
                                                            <td>:</td>
                                                            <td className='text-left'>{events.offenderName}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='text-right'>Pelanggaran</td>
                                                            <td>:</td>
                                                            <td className='text-left'>{events.violationType}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='text-right'>Dibuat pada</td>
                                                            <td>:</td>
                                                            <td className='text-left'>{events.createdAt.split('T')[0]}</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            ) : (
                                                <div></div>
                                            )}
                                        </div>
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 phone:px-6 phone:flex phone:flex-row justify-between">
                                <div></div>
                                <div className='w-1/5'>
                                    <button onClick={onClose} type="button"
                                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm">
                                        OK
                                    </button>
                                </div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ModalLacakPengaduan;