import React, { useState } from 'react';
import IconDropDown from "./icons/IconDropDown";

const Collapsible = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleCollapse = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="rounded p-3">
            <button
                className="flex items-center justify-start w-full px-1"
                onClick={toggleCollapse}
            >
                <IconDropDown dropopen={isOpen} />
                <span className='ml-2 hover:text-blue-400'>{title}</span>
            </button>
            <div
                className={`transition-all duration-300 ${
                    isOpen ? 'block' : 'hidden'
                }`}
            >
                <div className="p-4">{children}</div>
            </div>
        </div>
    );
};

export default Collapsible;