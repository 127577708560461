import axios from "axios";

const getVisitorsData = () => {
    return new Promise(async (resolve, reject) => {
        await axios.get('/api/stat/visitor').then((res) => {
            if (res.status === 200) {
                resolve(res.data)
            } else {
                const response = res
                reject('VISITOR : ' + response.message)
            }
        }).catch(err => reject(err));
    })
}

export default getVisitorsData;