import React, {useEffect, useRef, useState} from 'react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
import logo from "../logo.png";
import IconDropDown from "./icons/IconDropDown";

const CustomeNavbar = () => {
    var menuNav = [
        { name: 'Beranda', to: '/', child: null, active: false },
        { name: 'Profil', to: '/profil', child: [
            {name: 'Visi & Misi', to:'/visimisi', active: false},
            {name: 'Tugas & Fungsi', to:'/tugas', active: false},
            {name: 'Pegawai Inspektorat', to:'/pegawai', active: false},
            {name: 'Tentang', to:'/profil', active: false}
        ], active: false },
        { name: 'Layanan', to: '#', child: [
            { name: 'Pengaduan Online', to: '/pengaduan', child: null, active: false },
            { name: 'Lacak Pengaduan', to: '/lacakpengaduan', child: null, active: false },
            { name: 'Survei Masyarakat', to: '/surveimasyarakat', child: null, active: false },
        ], active: false },
        { name: 'Gratifikasi', to: '/gratifikasi', child: [
            { name: 'Pelaporan Gratifikasi', to: '/laporgratifikasi', child: null, active: false },
            { name: 'LHKPN', to: 'https://elhkpn.kpk.go.id/portal/user/login', child: null, active: false }
        ], active: false },
        { name: 'Publikasi & Informasi', to: '#', child: [
            { name: 'Berita & Kegiatan', to: '/berita', child: null, active: false },
            { name: 'Dokumen Publik', to: '/dokumen', child: null, active: false },
            { name: 'Peraturan', to: '/peraturan', child: null, active: false },
            { name: 'Surat Internal', to: '/suratinternal', child: null, active: false },
            { name: 'Galeri', to: '/galeri', child: null, active: false },
            { name: 'LHKPN Pejabat', to: '/lhkpnpejabat', child: null, active: false },
        ], active: false },
    ]
    const [allmenu, setAllMenu] = useState(menuNav);
    const dropdownRef = useRef(null);
    const [open, isOpen] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [showSubmenu, setShowSubmenu] = useState(false);

    const classNames = (...classes) => {
        return classes.filter(Boolean).join(' ')
    }

    const [isVisible, setIsVisible] = useState(false);

    const handleClickMenu = (name) => {
        setMenuOpen(!menuOpen);
        var newMenuNav = [];
        allmenu.map((item) => {
            if (item.name === name) {
                item.active = true;
            } else {
                item.active = false;
            }
            newMenuNav.push(item);
        });
        localStorage.setItem('menuOpen', JSON.stringify(newMenuNav));
    }

    const handleScroll = () => {
        if (window.scrollY > 100) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        if (JSON.parse(localStorage.getItem('menuOpen')) == null) {
            localStorage.setItem('menuOpen', JSON.stringify(menuNav));
            setAllMenu(menuNav);
            handleClickMenu('Beranda')
        } else {
            setAllMenu(JSON.parse(localStorage.getItem('menuOpen')))
        }

        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowSubmenu(false);
                isOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleDrawer = () => {
        isOpen(!open);
        if (open) {
            console.log('hello')
        }
    };

  return (
    <div ref={dropdownRef}>
        <nav className="mt-3 mb-3">
            <div className='mx-auto max-w-7xl px-2 phone:px-6 desktop:px-8'>
                <div className='relative flex h-16 items-center justify-between'>
                    <div className='absolute inset-y-0 left-0 flex tab:hidden items-center'>
                        {/* Mobile menu button*/}
                        <button onClick={toggleDrawer} className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-700 hover:bg-gray-50 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                            <span className="absolute -inset-0.5" />
                            <span className="sr-only">Open main menu</span>
                            {open ? (
                                <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                            ) : (
                                <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                            )}
                        </button>
                    </div>

                    { open ? (
                        // Mobile View
                        <div className='z-20 bg-gray-900 bg-opacity-70 h-full phone:fixed phone:top-0 phone:right-0'>
                            {/*<FadeIn delay={0.1} direction='left' fullWidth>*/}
                                <div className="bg-gray-900 w-60 h-full phone:fixed phone:top-0 phone:right-0 flex-1 items-center tab:flex phone:items-stretch">
                                    <div className='flex items-center p-5'>
                                        <img alt='img' src={ logo } className='h-5 w-auto' />
                                        <div className='ml-5'>
                                            <strong className='desktop:text-md tab:text-sky-800 phone:text-sm phone:text-white'>Inspektorat Daerah</strong><br/>
                                            <span className='desktop:text-sm phone:text-sm text-white'>Kabupaten Paniai</span>
                                        </div>
                                    </div>
                                    <div className="space-y-1 px-2 pb-3 pt-10">
                                        { allmenu.map((item) => (
                                            <div key={item.name}>

                                                {item.child == null ? (
                                                    <Link
                                                        key={item.name}
                                                        to={item.to}
                                                        className={classNames(
                                                            item.current ? 'text-orange-100' : 'text-white hover:text-sky-500',
                                                            'px-3 py-2 text-md'
                                                        )}
                                                        onClick={() => {
                                                            handleClickMenu(item.name)
                                                            setAllMenu(JSON.parse(localStorage.getItem('menuOpen')));
                                                        }}
                                                    >
                                                        {item.name}
                                                    </Link>
                                                ) : (
                                                    <div>
                                                        <Link
                                                            key={item.name}
                                                            to="#"
                                                            className={classNames(
                                                                item.active ? 'text-sky-500' : 'text-white hover:text-sky-500',
                                                                'inline-flex px-3 py-2 text-md'
                                                            )}
                                                            onClick={() => {
                                                                handleClickMenu(item.name);
                                                                setShowSubmenu(!showSubmenu);
                                                                setAllMenu(JSON.parse(localStorage.getItem('menuOpen')));
                                                            }}
                                                        >
                                                            {item.name}
                                                            {item.active ? (
                                                                <IconDropDown dropopen={showSubmenu}/>
                                                            ) : (
                                                                <IconDropDown dropopen={false}/>
                                                            )}
                                                        </Link>
                                                        <div className='ml-5'>
                                                            {item.child.map((child) => (
                                                                <Link
                                                                    key={child.name}
                                                                    to={child.to}
                                                                    className={classNames(
                                                                        child.active ? 'text-sky-900' : 'text-white hover:text-sky-500',
                                                                        'flex px-3 text-md'
                                                                    )}
                                                                >
                                                                    {child.name}
                                                                </Link>
                                                            ))}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                    <div className='text-center'>
                                        {isVisible && (
                                            <button
                                                onClick={toggleDrawer}
                                                className="hover:text-gray-700 text-white text-center rounded-full focus:outline-none"
                                            >
                                                <div className='rounded-full p-2'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                         viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                                                         className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                              d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                                    </svg>
                                                </div>
                                            </button>
                                        )}
                                    </div>
                                </div>
                            {/*</FadeIn>*/}
                        </div>
                    ) : (
                        <div className="desktop:flex flex-1 items-center tab:flex phone:items-stretch justify-start">
                            <div className="hidden desktop:block">
                                <div className="flex space-x-5">
                                    { allmenu.map((item) => (
                                        <div key={item.name}>
                                            <div>
                                                {item.child === null ? (
                                                    <Link
                                                        key={item.name}
                                                        to={item.to}
                                                        onClick={() => {
                                                            handleClickMenu(item.name)
                                                            setAllMenu(JSON.parse(localStorage.getItem('menuOpen')));
                                                        }}
                                                        className={classNames(
                                                            item.active ? 'text-sky-500 hover:text-sky-300' : 'text-sky-900 hover:text-sky-500',
                                                            'px-3 text-md'
                                                        )}
                                                    >
                                                        {item.name}
                                                    </Link>
                                                ) : (
                                                    <div>
                                                        <Link
                                                            to='#'
                                                            onClick={() => {
                                                                handleClickMenu(item.name)
                                                                setShowSubmenu(!showSubmenu)
                                                                setAllMenu(JSON.parse(localStorage.getItem('menuOpen')))
                                                            }}
                                                            className={classNames(
                                                                item.active ? 'inline-flex text-sky-500 hover:text-sky-300' : 'text-sky-900 hover:text-sky-500',
                                                                'inline-flex items-center justify-center px-3 text-md'
                                                            )}
                                                        >
                                                            {item.name}
                                                            {item.active ? (
                                                                <IconDropDown dropopen={showSubmenu}/>
                                                            ) : (
                                                                <IconDropDown dropopen={false}/>
                                                            )}
                                                        </Link>
                                                        {(item.active && (
                                                            <div>
                                                                {(showSubmenu && (
                                                                    <div className='absolute mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50'>
                                                                        {item.child.map((it) => (
                                                                            <Link
                                                                                key={it.name}
                                                                                to={it.to}
                                                                                className='block px-4 py-2 rounded-md text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900'>
                                                                                {it.name}
                                                                            </Link>
                                                                        ))}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </nav>
        <hr/>
    </div>
  )
}

export default CustomeNavbar