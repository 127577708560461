import React from 'react';
import Header from "../components/Header";
import CustomeNavbar from "../components/CustomeNavbar";
import BeritaContent from "../contents/BeritaContent";
import ToTop from "../components/ToTop";
import Footer from "../components/Footer";
import DetailBeritaContent from "../contents/DetailBeritaContent";

const DetailBerita = () => {
    return (
        <div>
            <Header/>
            <CustomeNavbar/>
            <DetailBeritaContent />
            <ToTop/>
            <Footer/>
        </div>
    );
};

export default DetailBerita;