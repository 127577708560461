import React, {useEffect} from 'react';
import IconTimer from "../icons/IconTimer";
import {Link} from "react-router-dom";

const DetailBerita = ({article}) => {
    useEffect(() => {
        scrollToTop()
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const formatDate = (date) => {
        if (date === null) {
            return "";
        }
        return date.split('T')[0].split('-')[2] + '/' + date.split('T')[0].split('-')[1] + '/' + date.split('T')[0].split('-')[0] + ' pada jam ' + date.split('T')[1].replace('Z', '')
    }

    return (
        <div>
            <div className='grid grid-cols-1 gap-5 m-10'>
                <Link to={`/berita`}>
                    <div className='flex flex-row'>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <polyline points="15 18 9 12 15 6"></polyline>
                        </svg>
                        <span>Kembali</span>
                    </div>
                </Link>
                <p className='text-4xl'>{article.title}</p>
                <div className='flex'>
                    <div className='mt-3'>
                        <IconTimer className='w-4'/>
                    </div>
                    <div>
                        <p className="mt-2 text-gray-400">Dibuat di
                            tanggal {formatDate(article.createdAt)}</p>
                    </div>
                </div>
                {/*<img className="w-full" src={article.imageUri == null ? 'https://via.placeholder.com/1980': article.imageUri}  alt=""/>*/}
                <article className='prose lg:prose-xl w-auto' dangerouslySetInnerHTML={{__html: article.content}}>
                    {/*{article.content.toString()}*/}
                </article>
            </div>

        </div>
    );
};

export default DetailBerita;