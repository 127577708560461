import React, {useEffect, useState} from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import axios from "axios";
import Loading from "./Loading";

const SlideGaleri = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const [slides, setSlide] = useState([]);

    useEffect(() => {
        getGalleries().then((res) => {
            const response = res.data;
            let galeriTemp = [];
            response.forEach((image) => {
                galeriTemp.push(image)
            })
            setSlide(galeriTemp)
        })


        // return () => clearInterval(interval);
    }, []);

    const getGalleries = () => {
        return new Promise(async (resolve, reject) => {
            await axios.get('/api/file/galleries').then((response) => {
                resolve(response);
            }).catch((error) => {
                reject(error);
            })
        })
    }

    const nextSlide = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === slides.length - 1 ? 0 : prevIndex + 1
        );
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? slides.length - 1 : prevIndex - 1
        );
    };

    return (
        <>
            <div className="relative w-full desktop:max-w-6xl phone:max-w-2xl mx-auto overflow-hidden">
                {slides.length === 0 ? (
                    <div className='flex items-center justify-center'>
                        <h1>Galeri kosong..</h1>
                    </div>
                ) : (
                    <div>
                        <div className="flex transition-transform duration-500 ease-in-out"
                             style={{transform: `translateX(-${currentIndex * 100}%)`}}>
                            {slides.map((slide, index) => (
                                <div key={index} className="min-w-full">
                                    <img src={`/${slide.path}`} alt={`${slide.originalName}`} className="w-full"/>
                                </div>
                            ))}
                        </div>
                        <button onClick={prevSlide}
                                className="absolute m-2 bg-transparent hover:bg-blue-950 hover:bg-opacity-60 hover:rounded-full top-1/2 left-0 transform -translate-y-1/2 p-2">
                            <ChevronLeftIcon className="w-6 h-6 text-white"/>
                        </button>
                        <button onClick={nextSlide}
                                className="absolute m-2 bg-transparent hover:bg-blue-950 hover:bg-opacity-60 hover:rounded-full top-1/2 right-0 transform -translate-y-1/2 p-2">
                            <ChevronRightIcon className="w-6 h-6 text-white"/>
                        </button>
                    </div>
                )}

            </div>
        </>
    )
        ;
};

export default SlideGaleri;