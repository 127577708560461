import React, {useEffect, useState} from 'react';
import Jumbotron from "../components/Jumbotron";
import TablePeraturan from "../components/TablePeraturan";
import axios from "axios";
import IconDownload from "../components/icons/IconDownload";
import {Link} from "react-router-dom";
import {EyeIcon} from "@heroicons/react/20/solid";
import {viewAlert} from "../SweetAlert/SweetAlert";

const SuratInternalContent = () => {
    const [document, setDocument] = useState([]);

    useEffect(() => {
        parseSuratInternalContent().then((res) => {
            const response = res.data;
            setDocument(response)
        }).catch(err => viewAlert("Masalah!", err, "error"))
    }, []);

    const parseSuratInternalContent = () => {
        return new Promise(async (resolve, reject) => {
            await axios.get('/api/internal-letter?fields=id,title,createdAt&sort=').then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(error.response.data.message);
            })
        })
    }

    return (
        <div>
            <Jumbotron title='Surat Internal'/>

            <section className='flex flex-col items-center justify-center pt-10 pb-20'>
                <div className='flex flex-col items-center justify-center pb-10'>
                    <p className='desktop:text-xl phone:text-lg'>Surat Internal Inspektorat</p>
                    <p></p>
                </div>
                <div className='desktop:w-[1000px]'>
                    <div className="overflow-x-auto">
                        <table className="table-auto min-w-full border-collapse divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                            <tr>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    NO
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    Nama File
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    Action
                                </th>
                            </tr>
                            </thead>
                            {document.length > 0 ? (
                                <tbody className="bg-white divide-y divide-gray-200">
                                {document.map((row, rowIndex) => (
                                    <tr key={rowIndex}>
                                        <td className="px-6 py-4 whitespace-nowrap">{rowIndex + 1}</td>
                                        <td className="px-6 py-4 whitespace-nowrap">{row.title}</td>
                                        <td className="flex px-6 py-4 whitespace-nowrap">
                                            <Link className='inline-flex hover:text-sky-500' title='Lihat' target='_blank'
                                                  rel="noopener noreferrer" to={`/admin/surat-internal/${row.id}/view`}>
                                        <span className='pt-2 pl-2 pr-2'>
                                            <EyeIcon className='w-5 h-5'/>
                                        </span>
                                            </Link>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            ) : (
                                <tbody className="bg-white divide-y divide-gray-200">
                                <tr>
                                    <td>No Data</td>
                                </tr>
                                </tbody>
                            )}

                        </table>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default SuratInternalContent;