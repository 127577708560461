import React from 'react';
import IconDownload from "./icons/IconDownload";

const TableLhkpnPejabat = ({ data }) => {
    return (
        <div>
            <div className="overflow-x-auto">
                <table className="table-auto min-w-full border-collapse divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                    <tr>
                        <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                            NO
                        </th>
                        <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                            Nama Pejabat
                        </th>
                        <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                            Jabatan
                        </th>
                        <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                            LHKPN
                        </th>
                    </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                    {data.length > 0 ? (
                        <>
                            {data.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    <td className="px-6 py-4 whitespace-nowrap">{rowIndex + 1}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{row.nama}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{row.jabatan}</td>
                                    <td className="flex px-6 py-4 whitespace-nowrap">
                                        <a className='inline-flex hover:text-sky-500' href={row.linkDownload}
                                           download={row.linkDownload}>
                                    <span className='pt-2 pl-2 pr-2'>
                                        <IconDownload/>
                                    </span>
                                            Unduh
                                        </a>
                                    </td>
                                </tr>
                            ))}
                        </>
                    ): (
                        <>
                            Data Kosong
                        </>
                    )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default TableLhkpnPejabat;