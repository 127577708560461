import React, {useEffect, useRef, useState} from 'react';
import NewsThumbnail from "./NewsThumbnail";
import {viewAlert} from "../SweetAlert/SweetAlert";
import {Link} from "react-router-dom";
import {getArticles, getCategories} from "../api/articles";

const Articles = () => {
    const [articles, setArticles] = useState([]);
    const [kegiatan, setKegiatan] = useState([]);
    const isMounted = useRef(false);

    useEffect(() => {
        if (isMounted.current) {
            return;
        }

        isMounted.current = true;
        fetchData()

        return () => {
            // Cleanup code here if necessary
        };
    }, []);

    const fetchData = async () => {
        await getCategories().then((res) => {
            return res.data
        }).then( async (resp) => {
            let artikelTemp = [];
            await getArticles().then((res) => {
                const response = res.data;

                response.forEach((data) => {
                    resp.forEach((category) => {
                        if (data.categoryId === category.id) {
                            data.categoryName = category.name;
                            artikelTemp.push(data)
                        }
                    })
                })

            }).catch(err => {
                viewAlert('Masalah!', err, 'error')
            });

            let tempArtikle = [];
            let tempKegiatan = [];
            artikelTemp.forEach((category) => {

                if (category.categoryName === "information") {
                    tempArtikle.push(category)
                } else if (category.categoryName === "activity") {
                    tempKegiatan.push(category)
                }
            })

            setArticles(tempArtikle);
            setKegiatan(tempKegiatan);
        }).catch(err => viewAlert('Masalah!', err, 'error'))
    }

    return (
        <div className="mx-auto">
            <div className="grid desktop:grid-cols-2 phone:grid-cols-1 gap-5">

                {articles.length === 0 ? (
                    <div>
                        <div>
                            <h1 className="text-3xl font-bold text-center mb-8">Informasi</h1>
                        </div>
                        <div className='flex items-center justify-center'>
                            <p>Tidak ada Informasi...</p>
                        </div>
                    </div>
                ) : (
                    <div className='bg-gray-200 rounded-lg'>
                        <h1 className="text-3xl font-bold text-center mt-5 mb-5">Informasi</h1>
                        <div className="grid desktop:grid-cols-2 phone:grid-cols-1 desktop:gap-5 phone:gap-0">
                            {articles.map((article, index) => (
                                <div key={article.id} className="m-3">
                                    <Link to={`/berita/${article.slug}`}>
                                        <NewsThumbnail
                                            imageUrl={article.image === "" ? "https://via.placeholder.com/500" : `/${article.image}`}
                                            title={article.title}
                                            description={article.description}
                                            date={article.createdAt}
                                        />
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {kegiatan.length === 0 ? (
                    <div>
                        <div>
                            <h1 className="text-3xl font-bold text-center mb-8">Kegiatan</h1>
                        </div>
                        <div className='flex items-center justify-center'>
                            <p>Tidak ada kegiatan...</p>
                        </div>
                    </div>
                ) : (
                    <div className='bg-gray-200 rounded-lg'>
                        <h1 className="text-3xl font-bold text-center mt-5 mb-5">Kegiatan</h1>
                        <div className="grid desktop:grid-cols-2 phone:grid-cols-1 desktop:gap-5 phone:gap-0">
                            {kegiatan.map((article, index) => (
                                <div key={article.id} className="m-3">
                                    <Link to={`/berita/${article.slug}`}>
                                        <NewsThumbnail
                                            imageUrl={article.image === "" ? "https://via.placeholder.com/500" : `${article.image}`}
                                            title={article.title}
                                            description={article.description}
                                            date={article.createdAt}
                                        />
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Articles;