import axios from "axios";

export const getAgendas = () => {
    return new Promise(async (resolve, reject) => {
        await axios.get('/api/agenda').then((res) => {
            if (res.status === 200) {
                let arrList = [];
                res.data.data.forEach((list) => {
                    let datalist = {
                        title: list.title,
                        date: list.startDateTime.split('T')[0],
                        text: list.description + ' Waktu mulai pukul ' + list.startDateTime.split('T')[1].split(':')[0] + ':' + list.startDateTime.split('T')[1].split(':')[1] + ' - ' + list.endDateTime.split('T')[1].split(':')[0] + ':' + list.endDateTime.split('T')[1].split(':')[1]
                    }
                    arrList.push(datalist);
                })
                resolve(arrList);
            }
        }).catch((err) => {
            return err;
        })
    })
}