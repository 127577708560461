import React from 'react';
import {Link} from "react-router-dom";
import FadeIn from "../components/FadeIn";
import Jumbotron from "../components/Jumbotron";

const PelaporanGratifikasiContent = () => {
    return (
        <div>
            <Jumbotron title='Gratifikasi' />
            <FadeIn delay={0.5} direction="right" padding fullWidth>
                <section className='flex w-[100%] h-[200px] items-center justify-center'>
                    <div className="inset-0 flex items-center justify-center">
                        <p className="text-gray-800 text-5xl font-bold">Pelaporan Gratifikasi</p>
                    </div>
                </section>
            </FadeIn>
            <FadeIn delay={0.5} direction="left" padding fullWidth>
                <section className='flex items-center mb-10'>
                    <div className='desktop:ml-[20%] desktop:mr-[20%]'>
                        <h1 className='flex text-2xl pt-5 font-bold text-sky-500'>Tentang Unit Pengendalian Gratifikasi (UPG)</h1>
                        <p className='pt-5 pb-5'>
                            Gratifikasi merupakan pemberian dalam arti luas. Pengaturan dan penyebutan gratifikasi secara spesifik dikenal sejak disahkannya Undang-Undang Nomor 20 Tahun 2001 tentang Perubahan atas Undang-Undang Nomor 31 Tahun 1999 tentang Pemberantasan Tindak Pidana Korupsi (UU Tipikor). Undang-undang memberikan kewajiban bagi pegawai negeri atau penyelenggara negara untuk melaporkan pada KPK setiap penerimaan gratifikasi yang berhubungan dengan jabatan dan berlawanan dengan tugas atau kewajiban penerima. Jika gratifikasi yang dianggap pemberian suap tersebut tidak dilaporkan pada KPK, maka terdapat resiko pelanggaran hukum baik pada ranah administratif ataupun pidana.
                        </p>
                        <h1 className='pt-5 text-2xl font-bold pb-5'>Peraturan yang Mengatur Gratifikasi</h1>
                        <ol className='list-decimal pl-5'>
                            <li>
                                Pasal 128 ayat (1) UU No. 31/1999 jo UU No. 20/2001 berbunyi: <br/>
                                <span className='italic'>“Setiap gratifikasi kepada pegawai negeri atau penyelenggara negara dianggap pemberian suap,
                                apabila berhubungan dengan jabatannya dan berlawanan dengan kewajiban atau tugasnya.”</span>
                            </li>
                            <li>
                                Pasal 120 ayat (1) UU No. 31/1999 jo UU No. 20/2001, berbunyi: <br/>
                                <span className='italic'>“Ketentuan sebagaimana dimaksud dalam Pasal 128 Ayat (1) tidak berlaku, jika penerima melaporkan gratifikasi yang diterimanya kepada KPK.”</span>
                            </li>
                        </ol>
                        <h1 className='pt-5 text-2xl font-bold pb-5'>Laporan Gratifikasi</h1>
                        <p className=''>Setiap Wajib Lapor Gratifikasi wajib menyampaikan laporan dalam hal:</p>
                        <ol className='pt-5 list-disc pl-10'>
                            <li>Telah menolak suatu pemberian gratifikasi</li>
                            <li>Telah menerima gratifikasi dan/atau</li>
                            <li>Telah memberikan gratifikasi</li>
                        </ol>
                        <p className='pt-5'>Laporan Gratifikasi sekurang-kurangnya memuat:</p>
                        <ol className='list-decimal pl-10'>
                            <li>Identitas pelapor, terdiri dari nama dan alamat lengkap, jabatan, unit kerja, alamat email dan/atau nomor telepon.</li>
                            <li>Bentuk dan jenis gratifikasi yang telah dilakukan, yaitu penolakan, penerimaan, pemberian dan/atau pemberian atas permintaan.</li>
                            <li>Spesifikasi wujud dari benda gratifikasi, contohnya uang, tiket perjalanan dan sebagainya.</li>
                            <li>Waktu dan/atau rentang waktu dan lokasi dilakukannya praktek gratifikasi.</li>
                            <li>Nama pihak/lembaga pemberi, penerima atau peminta gratifikasi.</li>
                            <li>Nilai/perkiraan nilai materi dari benda gratifikasi.</li>
                            <li>Dokumen kelengkapan pendukung lainnya.</li>
                        </ol>
                        <h1 className='pt-5 text-2xl font-bold pb-5'>Media Pelaporan Gratifikasi</h1>
                        <p className='pb-5'>Pelaporan Gratifikasi disampaikan melalui Gratifikasi Online (GOL) Komisi Pemberantasan Korupsi (KPK). Apabila di tempat Wajib Lapor Gratifikasi tidak dapat terhubung dengan dengan Gratifikasi Online (GOL) KPK maka pelaporan disampaikan secara langsung atau melalui pos menggunakan formulir penolakan, penerimaan, pemberian dan/atau permintaan gratifikasi sesuai format yang telah ditetapkan.</p>
                        <p className='pb-5'>Pelaporan Gratifikasi dapat dilakukan melalui tautan Gratifikasi Online (GOL) KPK berikut:</p>
                        <Link className='text-sky-500' to='https://gol.kpk.go.id' target='_blank'>Gratifikasi Online (GOL) KPK</Link>
                        {/*<p className='pt-5 pb-5'>Anda juga dapat mendownload Formulir Pelaporan Gratifikasi Milik KPK pada link berikut:</p>*/}
                        {/*<Link className='text-sky-500' to='https://drive.google.com/file/d/1LLuJzXNq7ys9U21Fq8hoZYGYN2qiAdBc/view' target='_blank'>Form Laporan Gratifikasi KPK</Link>*/}
                    </div>
                </section>
            </FadeIn>
        </div>
    );
};

export default PelaporanGratifikasiContent;