import React, {useEffect, useState} from 'react';
import TableLhkpnPejabat from "../components/TableLHKPNPejabat";
import Jumbotron from "../components/Jumbotron";

const LhkpnPejabatContent = ({listPejabat}) => {
    return (
        <div>
            <Jumbotron title='LHKPN' />
            <section className='relative w-[100%] h-[200px] items-center justify-center'>
                <div className="absolute inset-0 flex items-center justify-center">
                    <p className="text-gray-800 text-5xl font-bold">LHKPN Pejabat</p>
                </div>
            </section>
            <section className='flex items-center justify-center mb-10'>
                <div className='inset-0'>
                    <TableLhkpnPejabat data={listPejabat} />
                </div>
            </section>
        </div>
    );
};

export default LhkpnPejabatContent;