import React from 'react'
import FadeIn from '../components/FadeIn'
import { Link } from 'react-router-dom'
import MenuBig from "../components/MenuBig";
import FadeInMenu from "../components/FadeInMenu";
import ImageSlide from "../components/ImageSlide";
import FungsiSection from "../components/FungsiSection";
import GaleryCarousel from "../components/GaleryCarousel";
import Articles from "../components/Articles";
import Dirgahayu from "../components/Dirgahayu";

const HomeContent = () => {

    return (
        <div className='w-auto'>
            <section className='h-760 mb-10'>
                <div className='relative'>
                    <div className='bg-black-500 absolute h-760 w-full'>
                        <ImageSlide />
                    </div>
                    <div className='absolute inset-0 items-center justify-center'>
                        <div className='flex flex-col items-center justify-center mt-24'>
                            <div className=''>
                                <FadeIn delay={0.2} direction="down" padding fullWidth>
                                    <Dirgahayu />
                                </FadeIn>
                            </div>
                            <div>
                                <FadeIn delay={0.2} direction="down" padding fullWidth>
                                    <h1 className='text-left text-5xl desktop:text-5xl phone:text-3xl leading-tight xs:text-[64px] text-white max-w-[1050px]'>
                                        Selamat Datang di website <strong>Inspektorat Kab. Paniai</strong>
                                    </h1>
                                </FadeIn>
                                <FadeIn delay={0.5} direction="left" padding fullWidth>
                                    <h1 className='mt-[50px] text-left text-lg leading-tight xs:text-[64px] text-white max-w-[1050px]'>
                                        Adalah lembaga yang bertugas mengawasi dan memeriksa kegiatan Pemerintah Daerah untuk memastikan transparansi, efisiensi, dan kepatuhan terhadap peraturan, serta memberikan rekomendasi untuk perbaikan kinerja.
                                    </h1>
                                </FadeIn>
                                <FadeIn delay={0.4} direction='right' padding fullWidth>
                                    <Link to='/pengaduan' className='btn mt-[50px] bg-orange-600 p-5 rounded-md hover:bg-orange-400 text-white'>
                                        Buat Pengaduan
                                    </Link>
                                </FadeIn>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='h-600 mb-10'>
                <FungsiSection />
            </section>

            <section id='pelayanan' className='bg-blue-950 h-600'>
                <FadeIn delay={0.4} direction='down' padding fullWidth>
                    <div>
                        <p className='text-center text-white mt-10 text-3xl font-bold'>Layanan Inspektorat Daerah</p>
                        <div className='desktop:container desktop:w-96 mt-5'>
                            <p className='text-center text-white'>Inspektorat Daerah menyediakan beragam layanan yang bertujuan untuk memastikan transparansi, akuntabilitas, dan efektivitas pemerintahan daerah.</p>
                        </div>
                    </div>
                </FadeIn>
                <FadeInMenu delay={0.4} direction='right' padding fullWidth>
                    <div className="desktop:container mx-auto p-5">
                        <MenuBig />
                    </div>
                </FadeInMenu>
            </section>

            <FadeIn delay={0.4} direction='down' padding fullWidth>
                <section className='pt-10'>
                    <div className=' mb-10'>
                        <div className='h-600'>
                            <Articles />
                        </div>
                    </div>
                </section>
            </FadeIn>
        </div>
    )
}

export default HomeContent