import React, {useEffect, useRef, useState} from 'react';
import { motion } from 'framer-motion';
import CommentForm from "./CommentForm";
import logo from '../logo.png';
import { faXTwitter, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Link} from "react-router-dom";
import axios from "axios";
import Visitor from "./Visitor";
import {viewAlert} from "../SweetAlert/SweetAlert";
import getSettingsList from "../api/settings";

const Footer = () => {
    const years = new Date().getFullYear();
    const [dateAgenda, setDateAgenda] = useState('');
    const [alamat, setAlamat] = useState(''),
        [telp, setTelp] = useState(''),
        [email, setEmail] = useState(''),
        [linkx, setLinkX] = useState(''),
        [linkig, setLinkIg] = useState(''),
        [linkfb, setLinkfb] = useState('')

    const formatDate = (date) => {
        const days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];
        const months = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];

        const day = days[date.getDay()];
        const dayOfMonth = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();

        return `${day}, ${dayOfMonth} ${month} ${year}`;
    };
    const isMounted = useRef(false);

    useEffect(() => {
        if (isMounted.current) {
            return
        }
        isMounted.current = true;
        getSettingsList().then((res) => {
            getSettingFooter(res)
        }).catch(err => {
            viewAlert('Error', err, 'error');
        });

        return () => {}
    }, []);

    const getSettingFooter = (listsetting) => {
        listsetting.forEach((vm) => {
            const date = new Date();

            if (vm.key === 'address') {
                setAlamat(vm.value)
            }

            if (vm.key === 'telp') {
                setTelp(vm.value)
            }

            if (vm.key === 'email') {
                setEmail(vm.value)
            }

            if (vm.key === 'linkig') {
                setLinkIg(vm.value)
            }

            if (vm.key === 'linkx') {
                setLinkX(vm.value)
            }

            if (vm.key === 'linkfb') {
                setLinkfb(vm.value)
            }

            setDateAgenda(formatDate(date));
        })
    }

    return (
        <motion.footer
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="bg-gray-100 text-gray-600 py-10 text-center"
        >
            <div className="mx-auto">
                <div className='grid desktop:grid-cols-3 tab:grid-cols-2 phone:grid-cols-1 m-3 gap-2'>
                    <div className='mt-2  flex flex-col justify-center items-center'>
                        <div className='w-20 h-20 mb-5'>
                            <img alt='img' src={logo} className='object-cover'/>
                        </div>
                        <div>
                            <p className="text-lg">{alamat}, Paniai</p>
                            <p className="text-lg">Telp. {telp}</p>
                            {/*<p className="text-lg">Fax. 0967-21011</p>*/}
                            <p className="text-lg">Email: {email} </p>
                        </div>
                        <div>
                            {/* Icon Social Media */}
                            <div className='flex items-center justify-center mt-2'>
                                <Link to={linkx} target="_blank"><FontAwesomeIcon className='text-black' icon={faXTwitter} size="2x"
                                                            style={{marginRight: '10px'}} /> </Link>
                                <Link to={linkfb} target="_blank"><FontAwesomeIcon className='text-blue-600' icon={faFacebook} size="2x"
                                                             style={{marginRight: '10px'}}/></Link>
                                <Link to={linkig} target="_blank"><FontAwesomeIcon className='text-red-500' icon={faInstagram} size="2x"/></Link>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col justify-center items-center mr-5'>
                        {/*    List Menu Navbar di footer */}
                        <div className='grid grid-cols-2 gap-36'>
                            <div>
                                {/*<p className='font-bold'>Inspektorat</p>*/}
                                <ul className="text-left">
                                    <li className="m-2">
                                        <Link to="/" className="hover:text-gray-300">Beranda</Link>
                                    </li>
                                    <li className="m-2">
                                        <Link to="/profil" className="hover:text-gray-300">Tentang</Link>
                                    </li>
                                    <li className="m-2">
                                        <Link to="/berita" className="hover:text-gray-300">Berita</Link>
                                    </li>
                                    <li className="m-2">
                                        <Link to="/pengaduan" className="hover:text-gray-300">Pengaduan</Link>
                                    </li>
                                    <li className="m-2">
                                        <Link to="/peraturan" className="hover:text-gray-300">Dokumen</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className=''>
                                {/*<p className='font-bold'>Pengunjung</p>*/}
                                <ul className="text-left">
                                    <li className="m-2">
                                        <Link to="/berita" className="hover:text-gray-300">Berita & Kegiatan</Link>
                                    </li>
                                    <li className="m-2">
                                        <Link to="https://elhkpn.kpk.go.id/portal/user/login" className="hover:text-gray-300" target="_blank">LHKPN</Link>
                                    </li>
                                    <li className="m-2">
                                        <Link to="/surveimasyarakat" className="hover:text-gray-300">Survey Masyarakat</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="flex flex-col justify-center items-center mr-5">
                            <Visitor/>
                        </div>
                    </div>
                    <div className='ml-10 mr-10'>
                        <CommentForm/>
                    </div>
                </div>
                <hr />
                <div className='w-full h-full'>
                    <p className="text-md p-3 mt-10">Copyright © {years} <Link to="/profil">Inspektorat</Link> Paniai</p>
                </div>
            </div>
        </motion.footer>
    );
};

export default Footer;