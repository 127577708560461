import React from 'react';
import FadeIn from "./FadeIn";
import FadeInMenu from "./FadeInMenu";
import CalendersView from "./CalendersView";

const FungsiSection = () => {
    return (
        <div className='grid desktop:grid-cols-2 phone:grid-cols-1 gap-2'>
            <FadeIn delay={0.4} direction='right' padding fullWidth>
                <div className='w-screen'>
                    <p className='text-left text-blue-950 mt-10 text-3xl font-bold'>Agenda Kegiatan Inspektorat</p>
                    <div className='card'>

                    </div>
                    <CalendersView/>
                </div>
            </FadeIn>
            <div>
                <FadeIn delay={0.4} direction='down' padding fullWidth>
                    <div>
                        <p className='text-left text-blue-950 mt-10 text-3xl font-bold'>Tugas dan Fungsi</p>
                        <div className='mt-5 mr-6'>
                            <p className='text-left text-blue-950'>Tugas Inspektorat Daerah yakni membantu Bupati dalam membina dan mengawasi pelaksanaan urusan pemerintahan yang menjadi kewenangan Daerah dan Tugas Pembantuan oleh Perangkat Daerah.</p>
                        </div>
                    </div>
                </FadeIn>
                <FadeInMenu delay={0.4} direction='right' padding fullWidth>
                    <div className='grid desktop:grid-cols-2 phone:grid-cols-1 gap-2 m-3 mr-10'>
                        <div className='p-7'>
                            <p className='text-left font-medium'>Kebijakan Teknis</p>
                            <div className='mt-5 mr-6'>
                                <p className='text-left text-blue-950'>Perumusan kebijakan teknis di bidang pengawasan dan fasilitasi pengawasan.</p>
                            </div>
                        </div>
                        <div className='p-7'>
                            <p className='text-left font-medium'>Pengawasan Internal</p>
                            <div className='mt-5 mr-6'>
                                <p className='text-left text-blue-950'>Pelaksanaan pengawasan terhadap kinerja dan keuangan melalui audit, reviu, dll.</p>
                            </div>
                        </div>
                        <div className='p-7'>
                            <p className='text-left font-medium'>Pencegahan Korupsi</p>
                            <div className='mt-5 mr-6'>
                                <p className='text-left text-blue-950'>Pelaksanaan koordinasi pencegahan tindak pidana korupsi.</p>
                            </div>
                        </div>
                        <div className='p-7'>
                            <p className='text-left font-medium'>Reformasi Birokrasi</p>
                            <div className='mt-5 mr-6'>
                                <p className='text-left text-blue-950'>Penyelenggaraan dan pengawasan reformasi birokrasi, pelayanan publik.</p>
                            </div>
                        </div>
                    </div>
                </FadeInMenu>
            </div>
        </div>
    );
};

export default FungsiSection;