import React, {useEffect, useRef, useState} from 'react';
import {motion, AnimatePresence} from "framer-motion";
import { ArrowLeftIcon, ArrowRightIcon} from "@heroicons/react/24/outline";
import slide1 from "../assets/slide1.jpeg"
import slide2 from "../assets/slide2.jpeg"
import slide3 from "../assets/slide3.jpeg"
import {getBannerImage} from "../api/banners";
import {viewAlert} from "../SweetAlert/SweetAlert";

const ImageSlide = () => {
    const images = [
        slide1,
        slide2,
        slide3
    ];

    const [imageapi, setImageApi] = useState([]);
    const isMounted = useRef(false);
    const [index, setIndex] = useState(0);
    const [zoomed, setZoomed] = useState(false);

    useEffect(() => {
        if (isMounted.current) {
            return;
        }

        isMounted.current = true;
        getBanner().then((img) => {
            setImageApi(img);
        }).then(() => {
            const interval = setInterval(() => {
                nextSlide()
                console.log(index)
            }, 3000)

            const timeout = setTimeout(() => {
                setZoomed(true);
            }, 3000);

            return () => {
                clearTimeout(timeout)
                clearInterval(interval)
            };
        }).catch(err => viewAlert("Masalah!", err, "error"))

        return () => {}
    }, []);

    const getBanner = async () => {
        return await getBannerImage()
    }

    const prevSlide = () => {
        setIndex((index) => (index === 0 ? images.length - 1 : index - 1));
    };

    const nextSlide = () => {
        setIndex((index) => (index === images.length - 1 ? 0 : index + 1));
    };

    return (
        <div className="relative bg-blue-950 h-auto w-auto overflow-hidden">
            <button className="absolute top-0 left-0" onClick={prevSlide}>
                <ArrowLeftIcon />
            </button>
            <button className="absolute top-0 right-0" onClick={nextSlide}>
                <ArrowRightIcon />
            </button>

                {imageapi.length > 1 ? (
                    <AnimatePresence mode="wait" initial={false}>
                        <motion.img
                            key={index}
                            src={imageapi[index].path}
                            initial={{opacity: 0, scale: 1}}
                            animate={{opacity: 1, scale: zoomed ? 1.2 : 1}}
                            // exit={{ opacity: 1, scale: 0 }}
                            transition={{duration: 3}}
                            alt="Transparent"
                            className="w-full h-760 object-cover"
                        />
                    </AnimatePresence>
                ) : (
                    <div className='absolute w-full h-760 bg-black'>Hello</div>
                )}


        </div>
    );
};

export default ImageSlide;