import React from 'react';
import PelaporForm from "../components/PelaporForm";
import Jumbotron from "../components/Jumbotron";

const PengaduanContent = () => {
    return (
        <div className=''>
            <Jumbotron title='Buat Pengaduan' />
            <div className="desktop:ml-[20%] desktop:mr-[20%] tab:ml-[10%] tab:mr-[10%] phone:ml-10 phone:mr-10">
                <PelaporForm />
            </div>
        </div>
    );
};

export default PengaduanContent;