import axios from "axios";

const getSettingsList = () => {
    return new Promise(async (resolve, reject) => {
        await axios.get('/api/setting').then((res) => {
            resolve(res.data)
        }).catch(err => reject(err));
    })
}

export default getSettingsList;