import React from 'react';

const Marquee = () => {
    return (
        <div className="marquee-container bg-blue-950">
            <div className="marquee pt-2">
                <span className='text-lg'>Selamat Datang di website Inspektorat Kabupaten Paniai #Keunggulan Pengawasan, Pelayanan Terbaik: Inspektorat Daerah Mendukung Keadilan dan Kepatuhan!</span>
            </div>
        </div>
    );
};

export default Marquee;