import React, { useState } from 'react';

const RadioButton = ({options}) => {
    const [selectedOption, setSelectedOption] = useState('');

    const handleChange = event => {
        setSelectedOption(event.target.value);
    };

    return (
        <div className="desktop:p-6 phone:p-2 mx-auto bg-white flex flex-col items-start space-x-0">
            <div className="flex-shrink-0">
                {options.map(option => (
                    <div className="mb-4" key={option.value}>
                        <input
                            className="mr-2"
                            type="radio"
                            value={option.value}
                            checked={selectedOption === option.value}
                            onChange={handleChange}
                        />
                        <label>{option.label}</label>
                    </div>
                ))}
            </div>
            <div>
                <div className="text-xl font-medium text-black">Selected: {selectedOption}</div>
            </div>
        </div>
    );
}

export default RadioButton